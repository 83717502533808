import Api from "./Api";

export const WxUtil = {
    isInWechat: () => {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.includes("micromessenger");
    },
    getCode: ()=> {
        let curUrl = window.location.href;
        curUrl = Api.addParamsToPath(curUrl, { fetched: 1 });
        var appId = "wx0ce83182be6338a0";
        var redirectUri = encodeURIComponent(curUrl);
        var scope = "snsapi_base";
        var state = "STATE";
        var url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}`;
        window.location.href = url;
    }
};
