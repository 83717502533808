import React from "react";
import '../css/GreenConvention.css'

function GreenConvention() {
     document.title='绿色服务公约'

     return (
          <>
               <div className="bg">
                    <div className="bg_content">
                         <h4>亲爱的用户们：</h4>

                         <p>为保障游戏搭子平台的基本秩序，平台将严格按照《网络安全法》、《互联网直播服务管理规定》、《中华人民共和国未成年人保护法》等相关法律法规做好平台服务和内容监管，营造安全、绿色、和谐的网络环境。</p>
                         <p>平台将全面禁止涉政、涉黄、涉敏感话题严禁喊麦、脏话、低素质陪玩及性暗示、隐形诈骗、带侮辱性聊天内容，不得以任何形式向玩家以索要礼物形式进行诈骗，且禁止传播惊悚恐怖、神仙鬼怪等怪力乱神的网络小说或有声读物。一经发现，处理手段包括但不仅限于封禁违规账号，情节严重者，将对其举报或者移交司法机关追究其相关法律责任。</p>
                         <p>同时，我们也欢迎大家共同监督，及时举报违规行为，平台会第一时间严肃处理。希望大家自觉自律遵守以下文明公约，共同维护和谐、绿色平台，营造正能量充沛的网络音频空间。</p>
                         
                         
                         <p style={{textIndent:'0px'}}>01.严禁涉政违法内容</p>
                         <p>严禁出现任何反党反政府或带有侮辱诋毁党和国家的行为，严禁谈论任何违法违规内容</p>
                         <p style={{ marginTop: '0' }}>严禁传播封建迷信和宣扬邪教等相关内容，严禁散布谣言、暴力恐怖等扰乱社会秩序的行为</p>
                         <p style={{ marginTop: '0' }}>严禁传播或宣扬历史虚无主义，禁止传播惊悚恐怖、神仙鬼怪、僵尸、冥婚等怪力乱神的网络小说或有声读物等，禁止散布封禁迷信思想</p>
                         <p style={{ marginTop: '0' }}>严禁传播或教唆任何威胁生命健康的内容，如自杀自残、殴打或威胁他人、虐待小动物等</p>
                         <p style={{ marginTop: '0' }}>严禁抹黑、诋毁攻击他人，挑起事端，或泄露他人隐私信息等侵犯他人合法权益的行为</p>
                         <p style={{ marginTop: '0' }}>其他法律法规禁止的内容</p>

                         
                         <p style={{ textIndent: '0px' }}>02.严禁淫秽色情内容</p>
                         <p>严禁以任何形式传播或表演带有色情、涉黄擦边、引起他人性欲、低级趣味的内容</p>
                         <p style={{ marginTop: '0' }}>严禁传播其他淫秽色情、带有性挑逗的言论、歌曲、文字、图片等内容，包括但不限于磕泡泡、骚歌等</p>
                         <p style={{ marginTop: '0' }}>严禁传播情色游戏、动漫、淫秽低俗网站等相关内容</p>
                         <p style={{ marginTop: '0' }}>严禁传播一夜情、性虐待等有害信息</p>


                         <p style={{ textIndent: '0px' }}>03.严禁影响未成年人身心健康</p>
                         <p>严禁向未成年用户传播任何不良有害信息以及诱导未成年人进行违法违规行为</p>
                         <p style={{ marginTop: '0' }}>严禁宣扬或诱导未成年人进行有害其身心健康发展的行为，侵害未成年人的合法权益和身心健康包括但不限于吸烟、霸凌、暴力等</p>
                         <p style={{ marginTop: '0' }}>严禁传播情色游戏、动漫、淫秽低俗网站等相关内容</p>
                         <p style={{ marginTop: '0' }}>严禁对未成年人进行诱导刷钱、送礼等行为</p>


                         <p style={{ textIndent: '0px' }}>04.严禁扰乱平台秩序</p>
                         <p>禁止恶意煽动他人、有组织性挑起事端，进行恶意攻击或恶意举报等扰乱平台秩序的行为</p>
                         <p style={{ marginTop: '0' }}>禁止恶意大量刷屏无意义信息，影响他人平台体验以及公开厅经营的行为</p>
                         <p style={{ marginTop: '0' }}>禁止冒充官方人员，禁止发布虚假误导等含欺骗性质内容</p>
                    </div>
                    <p style={{ fontSize: '12px', textAlign: 'center', width: '90%', margin: "20px auto", color:'#a7a8aa'}}>在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与。</p>
               </div>
          </>
     );
}

export default GreenConvention;
