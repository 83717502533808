import React from "react";
import '../../css/magic.css'
import heart_one from "../../images/magic/恋曲_1@2x(1).png";
import heart_two from "../../images/magic/恋曲_2@2x(1).png";
import heart_three from "../../images/magic/恋曲_3@2x(1).png";
import heart_four from "../../images/magic/恋曲_4@2x(1).png";
import heart_five from "../../images/magic/恋曲_5@2x(1).png";

function LovesongMagic() {
     document.title ='恋曲魔法'

     return (
          <div>
               <div className="heart_one">
                    <img src={heart_one} alt="" style={{ width: "100%" }} />
               </div>
               <div className="heart_two">
                    {/*<img src={heart_two} alt="" style={{ width: "100%" }} />*/}
                   <img src="https://res-dev.51dazi.com/test/app-static/fXv-leVEVLU6-3huy6nXow.png?x-oss-process=image/resize,w_430,m_lfit" alt="" style={{ width: "100%" }} />
               </div>
               <div className="heart_three">
                    <img src="https://res-dev.51dazi.com/test/app-static/FKYZlv34Lrk6-3huy6nXow.png?x-oss-process=image/resize,w_430,m_lfit" alt="" style={{ width: "100%" }} />
               </div>
               <div className="heart_four">
                    <img src={heart_four} alt="" style={{ width: "100%" }} />
               </div>
               <div className="heart_five">
                    <img src={heart_five} alt="" style={{ width: "100%" }} />
               </div>
          </div>
     )
}

export default LovesongMagic;
