import React, { useState, useEffect } from "react";
import '../../css/PrivacyAgreement.css'

function PrivacyAgreement() {

    document.title = '隐私权政策'

    return (
        <div style={{ width: '100%' }} >

            <div style={{ width: '90%', margin: '5px auto', fontSize: '15px' }}>
                <h2 style={{ textAlign: 'center', fontWeight: 500, fontSize: '15px' }}>生效时间：2024年11月20日</h2>
                <h2 style={{ textAlign: 'center', fontWeight: 500, fontSize: '15px' }}>版本号： 1.0.0</h2>
                <p style={{ fontSize: '15px' }}>感谢您信任并使用游戏搭子，为更好地保障您的权益，依据相关法律法规和技术规范更新了《游戏搭子隐私保护指引》。</p>
                <p style={{ fontSize: '15px', marginBottom: '10px' }}>请您在使用/继续使用游戏搭子前仔细阅读、充分理解全文，并在同意全部内容后使用/继续使用。</p>

                <div>
                    <p style={{ textAlign: 'center', fontSize: '15px', marginBottom: '8.5px', color: "#0bb75b" }}>游戏搭子隐私保护指引</p>
                    <div style={{ fontSize: '15px', lineHeight: '20px',marginBottom: '10px' }}>
                        <p>游戏搭子是一款由天津搭子科技有限公司（以下简称“游戏搭子”或“我们”）提供服务的产品。游戏搭子的产品和服务包括：游戏搭子App、游戏搭子微信公众号、游戏搭子网站以及我们未来开发的可以实现游戏搭子全部或部分功能的网页、移动客户端、微信公众号等，以下合称“游戏搭子”，不适用于其他第三方通过游戏搭子向您提供的服务。</p>
                        <p>您在使用游戏搭子时，我们可能会收集和使用您的相关信息。我们深知个人信息对您的重要性，基于更好地向您展示和说明我们的隐私规则和您的用户权限，以及向您说明游戏搭子如何收集，使用和存储您的个人信息以及保护您的隐私权，我们将在本隐私保护指引中逐一说明我们收集的您的个人信息类型及其对应的用途，以便您了解我们针对特定功能所收集的具体个人信息的类别，使用理由及收集方式。</p>
                        <p>在使用游戏搭子前，请您仔细阅读并透彻理解本隐私保护指引，在确认充分理解并同意后使用相关产品或服务。如果您不同意本隐私保护指引任何内容，您应立即停止使用游戏搭子。一旦您开始使用游戏搭子，即表示您已充分理解并同意本隐私保护指引。</p>
                    </div>
                </div>


                <div style={{ fontSize:'14px',lineHeight:'22px' }}>
                    <p style={{ fontWeight: 600, marginBottom: '5px' }}>一、适用范围</p>
                    <p>本隐私保护指引适用于游戏搭子各产品和服务。</p>


                    <p style={{ fontWeight: 600, margin: '10px 0' }}>二、定义</p>
                    <ul>
                        <li>个人信息：指以电子或其他方式记录的、能够单独或与其他信息结合识别特定自然人身份或反映特定自然人活动情况的各种信息。</li>
                        <li style={{fontWeight:'600'}}>个人敏感信息：包括<span style={{ color:'#00b0f0'}}>身份证件号码、个人生物识别信息、银行或其他资金账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童的个人信息。</span>敏感信息在本指引中会做加粗提示。</li>
                        <li>个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</li>
                    </ul>

                    
                    <p style={{ fontWeight: 600, margin: '10px 0' }}>三、基本原则</p>
                    <p>我们将遵循合法、合理、必要的原则并根据本指引所述的目的，收集、使用和储存您在使用游戏搭子过程中主动提供或因使用而产生的个人信息。如果我们要将您的个人信息用于本指引未载明的其它用途，或基于特定目的将收集而来的信息用于其他目的，我们将在使用前，再次向您告知并征得您的同意。</p>
                    
                    <p style={{ fontWeight: 600, margin: '10px 0' }}>四、我们如何收集和使用您的个人信息</p>
                    <p style={{ fontWeight: 600, }}>（一）关于收集的个人信息及对应的用途</p>
                    <p>1.保障产品正常运行</p>
                    <p>为保障您正常使用平台的基础功能和正常服务、改进及优化我们的服务体验、保障您的账号安全，在您使用我们平台的服务或访问平台网页时，根据您在安装及使用过程中授予的具体权限，我们会收集您的<span style={{ fontWeight: '600' }}>设备型号，设备识别码（包括IMEI、IMSI、ICCID、IDFA、IDFV、GUID、OAID、SN ID、MEID、UserAgent）、OPEN ID、Union ID、软件列表、设备运行状态信息、系统版本、MCC、MNC、Mac地址信息（包括手机Mac地址及蓝牙Mac地址）、电信运营商、设备传感器信息。</span>如您不授权平台收集或使用前述信息，可能导致平台无法向您提供相关服务</p>
                    <p style={{ fontWeight: '600' }}>2.帮助您注册账号、提供活动通知、成为签约主播</p>
                    <ul>
                        <li>当您注册游戏搭子账号时，我们会收集您的<span style={{ color: '#00b0f0', fontWeight: '600' }}>手机号码</span>。在您使用“一键登录”服务时，经过您的同意，我们与合作运营商会使用您设备中的手机号码作为您的注册/登录账号并免于短信验证码核验，以便我们为您提供便捷的注册/登录服务。为您提供营销活动通知时，可能使用您注册的手机号码，以便我们和其他合作服务方与您取得联系。若您不希望使用“一键登录”服务，您可以通过获取验证码方式注册/登录，届时我们将通过发送短信验证码来验证您的身份是否有效，基于手机的自动读取信息功能，会读取您手机的短信以获取注册验证码。<span style={{ color: '#00b0f0', fontWeight: '600' }}>手机号码</span>属于您的个人信息，我们收集此类信息是为了满足相关法律法规的网络实名制要求。若您不提供这类信息，您可能无法正常完成注册和基本账户创建，进而无法使用游戏搭子产品及其功能。</li>
                        <li>您可以选择进一步完善本产品账号信息，可以向我们提供您的<span style={{ fontWeight: 600, }}>昵称、头像、性别、年龄</span>以获取附加服务。当您提供头像时，我们会请求您授权<span style={{ fontWeight: 600, }}>相机、相册权限</span>，如果您不同意授权或拒绝我们获取上述信息，您将无法获得该服务，但不影响您正常使用产品的其他功能。</li>
                        <li>当您通过第三方账号（例如微信）登录产品，您需要授权我们获取您登录所使用的第三方账号的公开信息（例如头像、昵称、性别等），并将该账号与您的产品账号进行绑定。如果您不同意授权或拒绝我们获取上述信息，您将无法通过第三方账号登录产品，但不影响您正常使用产品的其他功能。</li>
                    </ul>
                    <p>2）当您注册游戏搭子账号时，我们会收集您的生日、游戏相关信息（您最近常玩的游戏、您最高的段位、您所在的区服、您擅长的位置、您擅长的英雄），该信息收集方式为您自愿输入或选择，收集/使用目的为了便于您在平台使用社交功能或获得与您匹配度较高的平台用户推荐。若您不愿提供这类信息，可直接选择跳过，跳过操作不影响您正常使用游戏搭子的主要功能。</p>
                    <p>3）当您签约成为主播时，根据相关法律法规要求，您需要提供您的<span style={{ color: '#00b0f0', fontWeight: '600' }}>真实姓名、身份证号、身份证正反面照片</span>以完成实名验证。<span style={{ fontWeight: 600, }}>我们会将您的<span style={{ color: '#00b0f0' }}>真实姓名、身份证号</span>提供给我们合作的杭州数脉科技有限公司、阿里云计算有限公司任一家，以完成您的实名验证。</span>如果您拒绝提供上述认证信息，您将无法成为游戏搭子平台签约主播，无法使用特定房间的直播功能，但不影响您正常使用游戏搭子的其他功能。</p>
                    <p style={{ fontWeight: '600' }}>3.为您提供充值</p>
                    <p>当您进行购买平台道具时，我们会记录您的支付方式和<span style={{ color: '#00b0f0', fontWeight: '600' }}>资金账号</span>，以实现购买失败原路径退款，我们会根据您选择的支付方式将您的<span style={{ fontWeight: 600, }}>订单支付</span>相关信息、订单安全相关信息及其他反洗钱法律要求的必要信息，通过在应用程序中嵌入第三方支付软件工具开发包（SDK）与第三方共享。同时，基于维护支付安全、追溯网络交易行为，根据相关法律法规要求，我们会记录、保存并向您展示<span style={{ fontWeight: 600, }}>订单信息。如您拒绝提供上述信息，将使您无法完成平台道具购买或成为会员，但不影响您正常使用游戏搭子的其他功能。</span></p>
                    <p style={{ fontWeight: '600' }}>4.帮助您浏览房间（语聊房、开黑房）</p>
                    <p>您可以浏览房间列表、房间、房主或房间内用户个人资料页、排行榜、主播资料；在浏览的过程中，您可以关注您感兴趣的用户，也可以加他为好友进行消息互动；在此过程中您可使用平台上麦功能。为此，我们可能会收集使用游戏搭子的<span style={{ fontWeight: 600, }}>设备信息（设备型号、唯一设备标识符、操作系统、分辨率、运营商、电话号码）</span>，并可能访问您手机设备的<span style={{ fontWeight: 600, }}>应用列表信息及麦克风、相册、相机功能，</span>以便于您正常使用浏览、互动功能。此外，在您使用该功能的过程中，我们会自动收集您主动编辑上传的资料和使用过程中的详细情况，并作为网络日志保存，日志信息包括但不限于您公屏发布的文字信息，您上传的图片信息，您的购买记录及消耗记录，访问的日期和时间，您请求的操作系统信息、游戏搭子软件版本号、登录IP信息，好友记录包括您关注的人及添加为好友的人。当您在游戏搭子进入语聊房并使用语聊房进行听歌或者语音互动的时候，我们会访问您<span style={{ fontWeight: 600, }}>设备的麦克风权限和电话权限（通话状态权限），</span>以便您在房间进行正常的语音交流，或者当您手机有来电时停止房间的声音和麦克风录音功能。如您拒绝提供上述信息，您将无法使用游戏搭子房间部分功能，但不影响您正常使用游戏搭子的其他功能</p>
                    <p style={{ fontWeight: '600' }}>5.帮助您上传或发布信息、视频</p>
                    <p>当您使用评论、分享、动态发布等信息上传/发布功能时，我们会根据您的发布行为和所发布的内容类型，获取相应的权限，例如您<span style={{ fontWeight: 600, }}>上传或发布的内容为照片、视频</span>时，我们需要获取您的<span style={{ fontWeight: 600, }}>相册权限、外部存储权限</span>。同时，您上传的照片、评论、点赞、声音录音、视频信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息，我们不会对该等信息进行编辑或整理或者将其用于该功能以外的其他用途，但您知道并同意，该等信息是游戏搭子全部用户公开可见的，您的自主上传行为即视同您同意在游戏搭子上的全部用户均可对该等信息进行浏览，并可保存、下载、转发。<span style={{ fontWeight: 600, }}>请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您选择上传包含个人信息的图片，请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。</span></p>
                    <p style={{ fontWeight: '600' }}>6.帮助您通过游戏开黑功能实现快速匹配游戏队友和一键组队</p>
                    <p>您使用该功能的过程需要公开您的昵称、头像、等级、性别、房间名字、开黑的游戏以及您自定义公开的内容。您可通过【我】-【账号与安全】页面开启好友跟随进房的权限进行快速组队，基于您的授权，我们会收集您的好友列表信息，您也可以随时在【我】-【账号与安全】页面关闭好友跟随进房的权限。</p>
                    <p style={{ fontWeight: '600' }}>7.帮助您参加游戏搭子举办的各种活动</p>
                    <p>我们将会不时举办线上的营销运营活动，如邀请新用户、签到、分享、打卡、唱歌、赛事活动。可能需要您主动提交参与活动所必要的有关信息。比如，在您领取红包提现、兑奖时，为向税务机构完税，您需要提交<span style={{ color: '#00b0f0', fontWeight: '600' }}>身份证信息</span>；为了向您寄送奖品或者活动礼品，您还需要提供<span style={{ color: '#00b0f0', fontWeight: '600' }}>联系地址信息</span>。关于该等运营活动如何收集、使用您的个人信息，您可以参考相应 H5 页面内的信息收集使用提示文本。</p>
                    <p>在特定活动中，我们会读取您的<span style={{ fontWeight: 600, }}>剪贴板内容</span>以判断是否存在相关口令码（如邀请码、激活码）信息，仅当剪贴板有前述信息时，我们才会收集该信息在服务器做校验后，向您反馈您所需的内容或绑定相关权益。请您放心，我们不会收集、存储您的剪贴板其他信息，且单独的剪贴板信息无法识别您的特定身份。</p>
                    <p>如您拒绝提供上述信息，您将无法完整参加平台运营和互动活动，但不影响您部分参与，亦不会影响您正常使用游戏搭子的其他功能。</p>
                    <p style={{ fontWeight: '600' }}>8.为您提供“游戏豆提现”功能</p>
                    <p>当您使用“游戏豆提现”功能时，根据相关法律法规要求，您需要提供您的<span style={{ color: '#00b0f0', fontWeight: '600' }}>真实姓名、身份证号、身份证正反面照片</span><span style={{ fontWeight: 600, }}>以完成实名验证，我们会将您的<span style={{ color: '#00b0f0', fontWeight: '600' }}>真实姓名、身份证号</span>提供给我们合作的杭州数脉科技有限公司、阿里云计算有限公司任一家，以完成您的实名验证</span>。同时我们需要您提供<span style={{ color: '#00b0f0', fontWeight: '600' }}>银行卡信息（收款人、开户行、银行账号）</span>或<span style={{ color: '#00b0f0', fontWeight: '600' }}>支付宝账号信息</span>或<span style={{ color: '#00b0f0', fontWeight: '600' }}>微信账号</span>信息以便完成支付。<span style={{ fontWeight: 600, }}>我们还会将您的</span><span style={{ color: '#00b0f0', fontWeight: '600' }}>真实姓名、身份证号、银行卡信息（收款人、开户行、银行账号）</span><span style={{ fontWeight: 600, }}>提供给将为您提供提现相关服务的服务机构（名称和联系方式见具体签约页面），以便于其为您提供提现相关服务。</span>如果您拒绝提供上述认证信息，您将无法完成游戏豆提现，但不影响您正常使用游戏豆进行消费，亦不会影响您正常使用游戏搭子的其他功能</p>
                    <p style={{ fontWeight: '600' }}>9.您使用游戏搭子的各项附加功能时信息收集和使用</p>
                    <p>1.基于相机/摄像头的附加功能。您可在开启相机/摄像头权限后使用该功能进行扫码用于拍摄照片或视频上传、分享。同时，根据相关法律法规以及校验身份的需要，您在使用特定功能或服务前，需要进行实名验证，例如：当您申请开通直播服务成为游戏搭子主播时，如果拒绝实名验证，您将可能无法获得相关服务，但不影响其他功能的正常使用。在实名验证过程中，我们可能需要通过<span style={{ fontWeight: 600, }}>人脸识别</span>的方式进行校验以确认是您本人操作，我们仅获得校验的结果，不会保留相关信息，除非我们依照法律法规规定另行获取您的同意。上述信息包含敏感个人信息，您可以拒绝提供，如果拒绝提供，您将可能无法获得相关服务，但不影响其他功能的正常使用。</p>
                    <p>2.基于麦克风的语音技术的附加功能。您可在开启麦克风权限后使用麦克风实现语音连麦聊天或录音或发布音频动态。在此过程中，我们会收集您的<span style={{ fontWeight: 600, }}>录音内容</span>。请您知晓，即使您已同意开启麦克风权限，我们也仅会在您主动使用聊天或语音或发布音频动态功能时通过麦克风获取语音信息。</p>
                    <p>3.实现搜索服务。您使用产品的搜索服务时，我们会收集您的<span style={{ fontWeight: 600, }}>搜索关键字信息、日志记录</span>，用以向您展示搜索结果内容、搜索历史记录。您可以自主选择是否清除搜索历史记录。</p>
                    <p>4.消息通知功能。对于您在使用产品或服务的过程中所提供的联系方式（例如：<span style={{ color: '#00b0f0', fontWeight: '600' }}>手机号码</span>），我们在运营中可能会向其中一种或多种发送多类通知，用于<span style={{ fontWeight: 600, }}>消息告知、身份验证、安全验证、用户使用体验调研。</span></p>
                    <p>5.为了维护网络安全、保障良好生态：您的 IP 地址所对应的<span style={{ fontWeight: 600, }}>归属地域信息</span>将会展示在您的个人主页。境内展示到省（区、市），境外展示到国家（地区），信息以网络运营商数据为准。</p>
                    <p style={{ fontWeight: '600' }}>10.为您提供客户服务及处理争议</p>
                    <p>为了方便与您联系或帮助您解决问题，我们可能需要您提供姓名、手机号码、电子邮件及其他联系方式，必要时可能需要您提供用以验证真实身份的<span style={{ color: '#00b0f0', fontWeight: '600' }}>身份证件信息</span>、户籍信息。如您拒绝提供上述信息，我们可能无法向您及时反馈投诉、申诉或咨询结果</p>
                    <p>11.为您提供安全风控服务</p>
                    <p>为提高您使用相关服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过了解一些您的网络使用习惯来判断您账户的风险，并可能会记录一些我们认为有风险的<span style={{ fontWeight: 600, }}>URL</span></p>

                    <p style={{ fontWeight: '600' }}>12.收集、使用个人信息及对应的用途的变更</p>
                    <p>我们可能通过业务的发展，进行游戏搭子改进。当新功能或服务与注册、语聊、互动交流、搜索、查询、认证、虚拟财产场景相关时，收集与使用的个人信息属于与原目的具有直接关联。在与原目的无直接关联的场景下，我们收集，使用您的个人信息，会再次征得您的同意。</p>
                    <p>请注意：我们的多重服务，可让您之外与自己的社交网络圈分享信息，也与使用该功能的所有用户公开分享您的相关信息，例如，您在我们平台上传或发布的信息，您对其他人上传或发布的信息做出的回应，以及包括与这些信息有关的位置数据，音视频信息。使用我们服务的其他用户也有可能分享与您有关的信息包括您的位置数据，音视频信息，发布信息。特别是，我们的社交媒体服务，是专为使您与世界各地的用户共享信息而设计，您可以使共享信息实时，广泛地传递。只要您不删除共享信息，有关信息会一直留存在公共领域；甚至您删除共享信息，有关信息仍可能由其他用户或不受我们控制的非关联第三方独立地缓存，复制或存储，或由其他用户或第三方在公共领域保存。因此，请您谨慎考虑通过我们的服务上传，发布和交流的信息内容。在某些情况下，您可通过我们某些服务的隐私设定来控制有权浏览您共享信息的用户范围。</p>
                    <p style={{ fontWeight: '600' }}>（二）我们从第三方获得您个人信息的事实</p>
                    <p>为了给您提供更好的服务，您同意我们可以向第三方包括但不限于我们的关联公司，合作伙伴终端产品中获取您的个人信息，我们可能从第三方获取您授权共享的账户信息（包括但不限于昵称、头像、性别以及OPPEN ID、Union ID），并且在您同意本隐私保护指引后将您的第三方账户与您的账户绑定。我们依据本协议约定，在符合相关法律和法规规定的指向下，使用您的这些个人信息。 </p>
                    <p style={{ fontWeight: '600' }}>（三）使用个人信息的例外情况</p>
                    <p>根据相关法律法规的规定，在以下场景中，我们可以在不征得您的授权同意的情况下收集，使用一些必要的个人信息：</p>
                    <ul>
                        <li>与国家安全，国防安全直接相关的；</li>
                        <li>与公共安全，公共卫生，重大公共利益直接相关的；</li>
                        <li>与犯罪侦查，起诉，审判和判决执行等直接相关的；</li>
                        <li>出于维护您或其他个人的生命，财产等重大合法权益但又很难得到本人同意的；</li>
                        <li>所收集的个人信息是您自行向社会公众公开的；</li>
                        <li>从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道，政府信息公开等渠道；</li>
                        <li>根据您的要求长期和补充合同所必需的；</li>
                        <li>用于维护游戏搭子的产品和/或服务的安全稳定运行所必需的，例如：发现，处置产品或服务的故障；</li>
                        <li>法律法规规定的其他情形。</li>
                    </ul>




                    <p style={{ fontWeight: 600, margin: '10px 0' }}>五、我们如何共享、共同处理、转让、公开披露您的信息</p>
                    <p style={{ fontWeight: '600' }}>（一）共享您的个人信息</p>
                    <ul>
                        <li>向您提供我们的服务。我们可能向合作伙伴及其他第三方共享您的信息，让您正常使用您需要的服务。例如：提供支付服务的支付机构、提供数据服务（包括网络广告监测、数据统计、数据分析）的合作伙伴；</li>
                        <li>实现本指引“我们如何收集和使用您的个人信息”部分所述的其他目的；</li>
                        <li>履行我们在本指引或我们与您达成的其他协议中的义务和行使我们的权利；</li>
                        <li>在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过，这并不包括违反本指引中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息；</li>
                        <li>应您合法需求或经您的授权同意；</li>
                        <li>应您的监护人合法要求而提供您的信息；</li>
                        <li>根据与您签署的单项服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</li>
                        <li>基于学术研究而提供；</li>
                        <li>基于符合法律法规的社会公共利益而提供。</li>
                    </ul>
                    <p>我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本指引以及其他任何相关的保密和安全措施来处理信息。</p>
                    <p style={{ fontWeight: '600' }}>（二）转让</p>
                    <p>除非获取您的明确同意，我们不会将您的个人信息转让给任何公司，组织或个人。</p>
                    <p>在游戏搭子及其关联公司发生重组、合并、分立、收购、资产出售或破产清算情形或其他类似情形时，如涉及到个人信息转让，我们将要求受让方采用与本指引相同或更高的标准和要求进行处理和保护，否则我们将要求受让方重新向您征求同意。</p>
                    <p style={{ fontWeight: '600' }}>（三）公开披露</p>
                    <p>除非获取您的明确同意，在本隐私保护指引约定之外，我们不会公开披露您的个人信息。</p>
                    <p>根据法律，法律程序，诉讼或政府主管部门强制性要求的情况下，我们可能会向有关部门披露您的个人信息，但我们保证，在上述情况发生时，我们会要求披露请求方必须出具与之相应的有效法律文件，而被披露的信息采取符合法律和业界标准的安全防护措施。 </p>
                    <p style={{ fontWeight: '600' }}>（四）共享，共同处理，转让，公开披露个人信息授权同意的例外情况</p>
                    <p>您充分认识，以下场景中，我们共享、转让、公开披露您的个人信息无需征得您的授权同意：</p>
                    <ul>
                        <li>与国家安全和社会公共利益有关的；</li>
                        <li>与犯罪侦查，起诉，审判和判决执行等有关的；</li>
                        <li>出于维护个人信息主体或其他个人的生命，财产等重大合法权益但又很难得到本人同意的；</li>
                        <li>所收集的个人信息是个人信息主体自行向社会公众公开的；</li>
                        <li>从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道，政府信息公开等渠道；</li>
                        <li>违反法律规定或违反我们平台规则导致我们已对您采取必要措施；</li>
                        <li>根据您的要求长期和补充合同所必需的；</li>
                        <li>用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现，处置产品或服务的故障；</li>
                        <li>为合法的新闻报道所必需的；</li>
                        <li>学术研究机构或基于公共利益或基于非营利目的进行统计或学术研究所必要，并对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</li>
                        <li>法律法规规定的其他情形。</li>
                        <li>根据法律规定，共享、转移经去标识化处理的个人信息，并且确保数据接收方无法恢复并重新识别个人信息主体的，不属于个人信息的对外共享、转移及公开披露行为，对此类数据的保存及处理将无需额外向您通知并征得您的同意。</li>
                    </ul>

                    <p style={{ fontWeight: 600, margin: '10px 0' }}>六、第三方服务</p>
                    <p>为保障我们客户端的稳定运行、功能实现，使您能够享受和使用更多的服务及功能，我们可能会接入第三方服务商提供的SDK，以便向您提供更好的服务和用户体验。目前，我们接入的第三方服务商主要包括以下几种类型：</p>
                    <ul>
                        <li>用于消息推送功能，包括手机厂商Push推送、特定事件提醒；</li>
                        <li>用于支付相关服务，包括订单支付、交易行为核验、收入结算、支付信息汇总统计；</li>
                        <li>用于在您同意的情况下获取设备位置权限、搜集设备信息和日志信息；</li>
                        <li>用于第三方授权服务，包括第三方账号登陆、将有关内容分享至第三方产品；</li>
                        <li>用于优化产品性能，包括提高硬件配网能力、降低服务器成本、功能热修复；</li>
                        <li>用于账号安全、产品加固相关服务，包括网络监测、域名解析、防劫持、防黑产、反作弊、加解密服务。</li>
                        <li>我们接入的部分第三方SDK可能会收集您的个人信息，如您在我们的服务中使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息。我们会评估这类第三方服务收集个人信息的合法性、正当性、必要性，要求该等第三方对您的个人信息采取保护措施，并严格遵守相关法律法规与监管要求。您可以查看下方《游戏搭子接入第三方SDK目录》，了解我们主要接入的第三方SDK基本情况。</li>
                    </ul>

                    <div className="table_list">
                        <table>
                            <caption style={{ width: '100%', height: '40px', lineHeight: '40px', fontWeight: '600', border: '1px solid #DDD', borderBottom: 'none' }}>游戏搭子接入第三方SDK目录</caption>
                            <tr>
                                <th>序号</th>
                                <th>SDK 名称</th>
                                <th>收集信息范围</th>
                                <th>收集信息目的</th>
                                <th>隐私政策地址</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>APP 支付客户端 SDK</td>
                                <td>Android ID、OAID、AAID、SSID、BSSID；系统设置、系统属性、设备型号、设备品牌、操作系统；IP 地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 参数、Wi-Fi 列表</td>
                                <td>保障用户账户和资金安全以及支付服务的安全稳定运行。履行反洗钱、反恐怖融资、反电信网络诈骗等法定义务。实现网络链路的选择和优化，以提升用户体验</td>
                                <td style={{ overflowWrap: 'break-word' }}>https://opendocsE4%BB%98%E5%AEB%AF%20SDK%20%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96;Bugly</td>
                            </tr>

                            <tr>
                                <td>2</td>
                                <td>Bugly Android</td>
                                <td>日志信息（包括：第三方开发者自定义日志、Logcat日志以及APP崩溃堆栈信息）、设备ID（idfv）、联网信息、系统名称、系统版本以及国家码</td>
                                <td>实现上报程序bug信息的功能</td>
                                <td style={{ overflowWrap: 'break-word' }}>https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56</td>
                            </tr>


                            <tr>
                                <td>3</td>
                                <td>阿里云号码认证-android</td>
                                <td>网络类型、切换wifi和蜂窝网络通道、设备信息（含IP地址、设备制造商、设备型号、手机操作系统、SIM卡信息SIM State）</td>
                                <td>实现本机号码一键登录和认证</td>
                                <td style={{ overflowWrap: 'break-word' }}>https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112211045_86198.html?spm=a2c4g.11186623.0.0.727039eb0HflH4</td>
                            </tr>

                            <tr>
                                
                                <td>4</td>
                                <td>微信OpenSDK Android</td>
                                <td>访问网络状态、IMEI、IMSI、ICCID、MAC、BSSID、SSID、网络信息、位置信息、剪切板信息</td>
                                <td>提供微信第三方登录和微信社会化分享</td>
                                <td style={{ overflowWrap: 'break-word' }}>https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</td>
                            </tr>

                            <tr>
                                <td>5</td>
                                <td>环信IM</td>
                                <td>设备识别码（APNS Token）、Bundle ID、应用的应用包名、应用版本号、安装运行路径、设备类型、设备名称及型号、操作系统（版本信息）、网络连接类型及状态、IP地址</td>
                                <td>提供个性化的沟通体验</td>
                                <td style={{ overflowWrap: 'break-word' }}>https://www.easemob.com/protocol</td>
                            </tr>

                            <tr>
                                <td>6</td>
                                <td>声网-Agora 视频 SDK</td>
                                <td>设备品牌、设备型号、操作系统版本、CPU信息、内存使用情况、电池电量信息、屏幕分辨率、IP地址、网络接入方式和类型、频道内用户ID（在同一频道内标识用户的非重复的数字或字符串ID）、传感器信息（设备的屏幕旋转方向）</td>
                                <td>提供实时音视频功能和服务</td>
                                <td style={{ overflowWrap: 'break-word' }}>https://www.shengwang.cn/SDK-privacy-policy/</td>
                            </tr>

                            <tr>
                                <td>7</td>
                                <td>声网-Agora 音频 SDK</td>
                                <td>设备品牌、设备型号、操作系统版本、CPU信息、内存使用情况、电池电量信息、屏幕分辨率、IP地址、网络接入方式和类型、频道内用户ID
                                    （在同一频道内标识用户的非重复的数字或字符串ID）、传感器信息
                                    （设备的屏幕旋转方向）</td>
                                <td>提供实时音视频功能和服务</td>
                                <td style={{ overflowWrap: 'break-word' }}>https://www.shengwang.cn/SDK-privacy-policy/</td>
                            </tr>
                        </table>
                    </div>
                    <p>以上第三方服务由相关的第三方负责运营。您使用该等第三方服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本指引）约束，您需要仔细阅读其条款。本指引仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。如您发现这些第三方社交媒体或其他服务存在风险时，建议您终止相关操作以保护您的合法权益并及时与我们取得联系。</p>

                    <p style={{ fontWeight: 600, margin: '10px 0' }}>七、个人信息的权利保障机制</p>
                    <p>我们非常认可您对个人信息的关注，并尽全力保护您对于自己的个人信息访问，更正，删除以及撤回同意的权利，以使您拥有充分的能力保障您的个人信息安全。您的权利包括</p>
                    <p style={{ fontWeight: 600, }}>1.查询和更正您的个人信息</p>
                    <p>您可以通过以下方式自行查询和更正您的个人信息：</p>
                    <p>资料信息：如果您希望查询或编辑您的账户中的个人资料，您可以登录游戏搭子在【我】-【编辑资料】页面添加、修改、删除您的头像、昵称、性别、生日、个性签名。</p>
                    <p>如果您无法通过上述方式访问您需要查询的个人信息，您可以随时通过本指引披露的联系方式联系我们，在合理要求下，我们将在十五个工作日内回复您的请求</p>
                    <p style={{ fontWeight: 600, }}>2.删除您的个人信息</p>
                    <p>您有权通“1.查询和更正您的个人信息”中列明的方式对已经填写的信息进行删除。同时，在以下情形中，您可以通过本指引披露的联系方式联系我们，向我们提出删除个人信息的请求，我们将在十五个工作日内回复您的请求：</p>
                    <p>1）如果我们处理个人信息的行为违反法律法规；</p>
                    <p>2）如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
                    <p>3）如果我们处理个人信息的行为严重违反与您的约定；</p>
                    <p>4）如果您不再使用我们的产品或服务，或您主动注销账号；</p>
                    <p>5）如果我们永久不再为您提供产品或服务</p>
                    <p style={{ fontWeight: 600, }}>3.撤回已同意的授权</p>
                    <p>您可以在个人设备系统中操作给予或撤回通知、位置信息、相机、相册、文件存储和访问、麦克风授权同意，在不同的设备中，权限显示方式以及关闭方式可能有所不同，具体请参考设备以及系统开发方说明或指引，您也可以在APP内设置及账号与安全中关闭或撤回相关同意或授权。</p>
                    <p>对于其他信息处理授权的撤回，您可以通过本指引披露的联系方式联系我们进行处理。</p>
                    <p>当您撤回同意后，我们将不再处理相应的个人信息，但请您理解，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的特定功能和/或服务</p>
                    <p style={{ fontWeight: 600, }}>4.注销您的账号</p>
                    <p>如果您需要注销您的游戏搭子账号，您可以在游戏搭子界面【我】-【账号与安全】-【注销账号】选项下按照指引操作进行账号注销。我们在核实用户身份后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理，除非法律法规对我们存储您的个人信息的期限另有规定</p>
                    <p style={{ fontWeight: 600, }}>5.获取个人信息副本</p>
                    <p>您有权获取您的个人信息副本，您可以联系我们，我们会在验证您的用户身份后的十五天内对您的请求进行处理。</p>
                    <p style={{ fontWeight: 600, }}>6.我们如何响应您的请求</p>
                    <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在十五个工作日内做出答复。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
                    <p>1）国家安全、国防安全直接相关的； </p>
                    <p>2）与公共安全、公共卫生、重大公共利益直接相关的； </p>
                    <p>3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
                    <p>4）有充分证据表明您存在主观恶意或滥用权利的；</p>
                    <p>5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
                    <p>6）涉及商业秘密的。</p>
                    

                    <p style={{ fontWeight: 600, margin: '10px 0' }}>八、Cookie的使用</p>
                    <p>为使您获得更轻松的访问体验，您使用我们提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做是帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie，Flash Cookie，您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。</p>
                    <p>请您理解，我们的某些服务只能通过使用“Cookie”才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝我们的Cookie，但这一举动在某些情况下可能会影响您安全访问游戏搭子相关网站和使用我们提供的服务。</p>
                    <p>您可以通过浏览器设置拒绝或管理cookies或web beacon。但请注意，如果停用cookies或web beacon，您有可能无法享受最佳的服务体验，某些服务也可能无法正常使用。 </p>


                    <p style={{ fontWeight: 600, margin: '10px 0' }}>九、自启动和关联启动说明</p>
                    <p>为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本应用须使用（自启动）能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的。</p>


                    <p style={{ fontWeight: 600, margin: '10px 0' }}>十、我们如何保护和存储您的个人信息</p>
                    <p style={{ fontWeight: 600, }}>1.信息安全保护</p>
                    <p>为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。我们会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力做到使您的信息不被泄漏、毁损或丢失。</p>
                    <p>您的账户均有安全保护功能，请妥善保管您的账户及密码信息。我们将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解在信息网络上不存在“完善的安全措施”。</p>
                    <p>请您妥善保护个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即联系我们客服，以便我们采取相应措施</p>
                    <p style={{ fontWeight: 600, }}>2.信息的存储</p>
                    <p>1）信息存储的方式和期限</p>
                    <p>我们会通过安全的方式存储您的信息，包括本地存储（例如利用APP进行数据缓存）、数据库和服务器日志。</p>
                    <p>我们只会在达成本指引所述目的所需的最短期限内保留您的个人信息，除非法律法规有强制的存留要求，例如，根据《中华人民共和国电子商务法》及配套法规的要求，您的订单类交易数据，将会至少保留3年。我们判断前述期限的标准主要包括并以其中较长者为准：</p>
                    <p>A.完成与您相关的服务目的、维护相应服务及业务记录、应对您可能的查询或投诉；</p>
                    <p>B.保证我们为您提供服务的安全和质量；</p>
                    <p>C.您是否同意更长的留存期间；</p>
                    <p>D.是否存在保留期限的其他特别约定。</p>
                    <p>2）信息存储的地域</p>
                    <p>我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。</p>
                    <p>目前我们不会跨境传输或存储您的个人信息。将来如需跨境传输或存储的，我们会向您告知信息出境的目的、接收方、安全保证措施和安全风险，并征得您的同意。</p>
                    <p>3）产品或服务停止运营时的通知</p>
                    <p>当我们的产品或服务发生停止运营的情况时，我们将在相关服务停止运营后停止继续收集您的个人信息。我们将以推送通知、公告形式将停止运营通知向您送达，并删除或匿名化处理您的个人信息，法律法规另有规定的除外。</p>
                    
                    
                    <p style={{ fontWeight: 600, margin: '10px 0' }}>十一、未成年人保护</p>
                    <ul>
                        <li>未满十八岁的未成年人请在父母或监护人的陪同下阅读本隐私保护指引，使用我们的产品和服务必须得到监护人的同意。我们重视对未成年人个人信息的保护，未成年用户务必请监护人仔细阅读本隐私保护指引，并应确保已征得您的监护人同意后使用我们的服务并向我们提供您的信息。如您的监护人不同意您按照本指引使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们。</li>
                        <li>如您注册或使用游戏搭子，根据相关法律法规的规定，若您是14周岁以下的儿童，在使用游戏搭子前，应当按照注册、使用流程，事先取得您的家长或法定监护人的同意，并由您的家长或法定监护人帮助您完成游戏搭子的注册流程，以便您能使用我们提供的产品或服务。</li>
                        <li>若您是儿童的监护人，当您在帮助儿童完成产品或服务的注册、使用前，应当仔细阅读本指引和《游戏搭子儿童隐私保护声明》，决定是否同意本指引和《游戏搭子儿童隐私保护声明》并帮助儿童进行注册、使用，以便儿童能使用我们提供的产品或服务。</li>
                        <li>我们不会在知情的情况下收集未成年人的个人信息。除非监护人同意，未成年人请不要注册账户或发送自己的<span style={{ color: '#00b0f0', fontWeight: '600' }}>姓名、住址、电话、邮件地址</span>给我们。我们将根据国家相关法律法规及本指引的规定保护未成年人用户信息的保密性及安全性。如果我们不小心收集到了未成年人的信息，我们在知情后会尽快删除。</li>
                        <li>我们不会在未经未成年人的监护人同意的情况下，显示未成年人个人信息的搜索结果；如未成年人的监护人要求我们删除、屏蔽游戏搭子上与其有关的未成年人个人信息的，请联系我们。</li>
                        <li>特别的，为更好的保护未成年人隐私权益，我们提醒您慎重发布包含未成年人影像的内容，一经发布，即视为您已取得监护人同意展示未成年人的肖像、声音，且允许我们依据用户服务协议和本隐私保护指引使用、管理该与未成年人相关的内容。如权利人通知我们您发布的内容侵犯未成年人的权利，出于保护权利人及未成年人权利的考虑，我们有权对您发布的内容进行处理。</li>
                        <li>对于未成年用户使用本服务过程中的行为，以及因其使用本服务而产生的一切后果由未成年人及其监护人承担，我们不承担任何责任。</li>
                    </ul>


                    <p style={{ fontWeight: 600, margin: '10px 0' }}>十二、隐私保护指引的修订</p>
                    <p>我们可能适时修订隐私保护指引，该修订构成本隐私保护指引的一部分。未经您明确同意，我们不会削减您按照本指引所应享有的权利。对于隐私保护指引发生的重大变化，我们可能会通过在网页上的显著位置发布相关条款或发布推送等形式通知用户。若您不同意修订后的隐私保护指引，您有权并应立即停止使用游戏搭子，若您继续使用游戏搭子，即表示同意接受修订的隐私保护指引的约束。</p>


                    <p style={{ fontWeight: 600, margin: '10px 0' }}>十三、管辖与法律适用</p>
                    <ul>
                        <li>本隐私保护指引的成立、生效、履行、解释以及争议的解决均适用中华人民共和国法律，不适用冲突法。</li>
                        <li>若您与我们之间发生任何争议或纠纷，双方应友好协商；如协商不成，应将争议提交广州市天河区人民法院管辖。</li>
                        <li>本隐私保护指引条款无论因何种原因导致部分无效，其余条款仍然有效，对双方具有约束力。本隐私保护指引任何条款的放弃均不应被视为对该等条款或任何其他条款的进一步或继续放弃，我们在本隐私保护指引项下未主张的任何权利或条款，不构成对该权利或条款的放弃。</li>
                    </ul>


                    <p style={{ fontWeight: 600, margin: '10px 0' }}>十四、关于联系我们</p>
                    <p>我们设立了个人信息保护专职部门，如您对游戏搭子隐私保护政策、儿童个人信息有任何疑问、意见或建议，或有关于网络信息安全的投诉和举报，您可通过以下方式与我们取得联系：</p>
                    <p>联系人：游戏搭子客服</p>
                    <p>联系邮箱：daziapp@163.com</p>
                    <p>为保障安全，我们可能会先要求您验证自己的身份，然后再处理您的请求。您可能需要提供书面请求，或以其他方式证明您的身份。我们将尽快审核所涉问题，一般情况下，我们会在验证您的用户身份后的十五个工作日内予以回复。</p>
                    <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险、属于授权同意例外情况或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</p>


                    <p style={{ textAlign: 'center', color: "#0bb75b" }}>游戏搭子儿童隐私保护声明</p>
                    <p>我们致力于保护使用游戏搭子产品和服务的儿童的个人信息。《游戏搭子儿童隐私保护声明》（以下简称“本声明”）和《游戏搭子隐私保护指引》旨在向监护人（以下简称“您”）和儿童说明我们如何收集、使用、存储和处理儿童的个人信息，以及我们为您与儿童提供的访问、更正、删除和保护这些信息的方式。</p>
                    <p>儿童监护人特别说明：</p>
                    <p>如果您是儿童用户的监护人，请您仔细阅读和选择是否同意本声明。我们希望请您与我们共同保护儿童的个人信息，教育引导儿童增强个人信息保护意识和能力，指导、提醒和要求他们在未经您同意的情况下，不向任何网络产品和服务提供者提供任何个人信息。</p>
                    <p>儿童特别说明：</p>
                    <p>如果您是14周岁以下的未成年人，您需要和您的监护人一起仔细阅读本声明，并在征得您的监护人同意后，使用我们的产品、服务或向我们提供信息。</p>
                    <p>请您在仔细阅读、充分理解《游戏搭子隐私保护指引》和本声明后，选择是否同意相关隐私政策以及是否同意您和您监护的儿童使用我们的产品或服务。如果您不同意相关隐私政策和本声明的内容或不同意提供服务所必要的信息，将可能导致我们的产品或服务无法正常运行，或者无法达到我们拟达到的服务效果，您和您监护的儿童可能无法正常使用我们的产品、服务或相关的具体业务功能。</p>
                    <p>您点击“同意”即表示您同意我们按照《游戏搭子隐私保护指引》和本声明的约定收集、使用和处理您和您监护的儿童的信息。</p>
                    <p>请您理解，本声明仅适用于我们面向14周岁以下的儿童提供的产品或服务、具有专门面向14周岁以下儿童的功能板块的产品或服务，或者需要用户输入生日、年龄信息以便识别用户年龄的产品或服务。针对不能识别或不主动识别用户年龄的产品或服务，将适用《游戏搭子隐私保护指引》。</p>
                    <p>我们提醒您，本声明中与您和您监护的儿童的权益（可能）密切相关的重要条款，我们已采用加粗字体来特别提醒您，请您重点查阅。</p>
                    <p>如您在阅读本声明过程中有任何疑问或权利请求的，我们为您提供了专门的反馈渠道，具体参见本声明中详列的联系方式，我们会尽快为您作出解答。</p>
                    <p style={{ fontWeight: 600, }}>我们收集哪些信息</p>
                    <p>游戏搭子严格遵守法律法规的规定及与用户的约定，根据用户选择产品或服务的具体情况，收集您和您监护的儿童的以下个人信息。</p>
                    <p>1.针对专门面向14周岁以下的儿童提供的产品和服务或部分功能板块，或需要用户输入生日、年龄信息以便识别用户年龄的产品或服务，如注册或使用前述产品或服务，我们会要求用户填写个人生日信息，以识别该用户是否为14周岁以下的儿童，并按照法律法规的规定，在获得监护人同意之后才允许儿童继续使用相关产品或服务。</p>
                    <p>2.当我们根据用户提供的上述信息识别出用户为14周岁以下的儿童时，我们会收集您的联系方式，包括<span style={{ color: '#00b0f0', fontWeight: '600' }}>手机号码、电子邮箱</span>，并使用这些信息与您联系，验证您与您监护的儿童的监护关系。根据产品或服务验证监护关系、征得您同意的具体方案，我们也可能会将您的账号与儿童账号进行关联，以便保护您和您监护的儿童的个人信息权益。在某些涉及交易的产品或服务中，我们可能会要求收集和绑定您的银行卡相关信息，以便您知悉和管理儿童用户的交易行为。如产品或服务涉及到上传头像功能，我们强烈建议您指导儿童在使用我们产品或服务的过程中不要上传真实的本人肖像照片。</p>
                    <p>3.在儿童使用产品或服务过程中，我们会收集相关信息，具体请查阅《游戏搭子隐私保护指引》“我们如何收集和使用您的个人信息”一节，详细了解我们收集的其他儿童个人信息。</p>
                    <p>4.如我们需要超出上述收集范围收集您或您监护的儿童的个人信息，我们将再次告知您，并征得您的同意。</p>
                    <p>请您理解，您可以选择是否填写或向我们提供特定的信息，但您如果不填写或提供某些特定的信息，将可能导致我们的产品或服务无法正常运行，或者无法达到我们拟达到的服务效果，您和您监护的儿童可能无法正常使用我们的产品、服务或相关的具体业务功能。</p>
                    <p style={{ fontWeight: 600, }}>我们如何使用这些信息</p>
                    <p>1.我们严格遵守法律法规的规定和与用户的约定，将收集的您和您所监护的儿童的信息用于以下用途：</p>
                    <ul>
                        <li>验证儿童和监护人身份</li>
                        <li>提供必要的产品或服务、产品或服务的具体功能</li>
                        <li>用于优化产品或服务、安全保障、服务调查目的</li>
                    </ul>
                    <p>2.如果我们需要超出上述用途使用您或您监护的儿童的个人信息，我们将再次告知您，并征得您的同意。</p>
                    <p>3.共享、转移、披露儿童个人信息的情形</p>
                    <p>我们遵照法律法规的规定，严格限制共享、转移、披露儿童个人信息的情形。</p>
                    <ul>
                        <li>一般情况下，我们不会向第三方分享儿童的个人信息。我们仅在监护人事先同意的情况下，才可能与第三方分享儿童的个人信息。</li>
                        <li>与监护人分享<p>我们会将部分儿童个人信息（如儿童用户名、账号、密码，以及该儿童用户在我们某些产品和服务中的交易内容）与监护人进行分享，监护人可以访问和管理相关个人信息。</p></li>
                        <li>仅为实现外部处理的目的，我们可能会与第三方合作伙伴（第三方服务供应商、承包商、代理、应用开发者，例如，代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置服务的地图服务供应商）（他们可能并非位于您所在的法域）分享儿童的个人信息，让他们按照我们的说明、隐私政策以及相关的保密和安全政策来为我们处理上述信息。</li>
                        <p>如我们委托第三方处理儿童个人信息的，我们会对受委托方及委托行为进行安全评估，签署委托协议，要求受委托方的处理行为不得超出我们的授权范围，我们委托第三方处理儿童个人信息主要用于以下用途：</p>
                        <li>向儿童提供我们的服务；</li>
                        <li>实现“我们如何使用这些信息”部分所述目的；</li>
                        <li>履行我们在《游戏搭子隐私保护指引》和本声明中的义务和行使我们的权利；</li>
                        <li>理解、维护和改善我们的服务。</li>
                        <p>如我们与上述第三方分享您和儿童的信息，我们将进行安全评估并采用加密、匿名化、去标识化处理保障您和儿童的信息安全。</p>
                        <li>随着我们业务的持续发展，当发生合并、收购、资产转让等交易导致向第三方分享您和儿童的个人信息时，我们将通过推送通知、公告等形式告知您相关情形，按照法律法规及不低于本声明所要求的标准继续保护或要求新的管理者继续保护您和儿童的个人信息。</li>
                        <li>我们可能会将所收集到的儿童信息在进行匿名化处理后用于大数据分析，如形成不包含任何个人信息的热力图、洞察报告等。</li>
                        <p>我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的信息，用于了解用户如何使用我们服务或让公众了解我们服务的总体使用趋势。</p>
                        <li>一般情况下，我们禁止对外披露儿童个人信息，但我们可能基于以下目的披露儿童的个人信息：</li>
                        <li>遵守适用的法律法规等有关规定；</li>
                        <li>遵守法院判决、裁定或其他法律程序的规定；</li>
                        <li>遵守相关政府机关或其他法定授权组织的要求；</li>
                        <li>我们有理由确信需要遵守法律法规等有关规定；</li>
                        <li>为执行相关服务协议或本声明、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途。</li>
                    </ul>
                    
                    
                    <p style={{ fontWeight: 600, }}>存储儿童个人信息</p>
                    <p>1.我们会采取加密技术措施存储儿童个人信息，确保信息安全。游戏搭子存储儿童个人信息，不会超过实现其收集、使用目的所必需的期限。请查阅《游戏搭子隐私保护指引》“我们如何保护和存储您的个人信息”章节，详细了解我们如何存储和保护儿童个人信息。</p>
                    <p>2.当我们的产品或服务发生停止运营的情形或您和儿童的个人信息存储期限届满时，我们将采取例如，推送通知、公告等形式通知您和儿童，并在合理的期限内删除或匿名化处理您和儿童的个人信息。</p>
                    <p style={{ fontWeight: 600, }}>管理儿童个人信息</p>
                    <p>在儿童用户使用我们的产品或服务期间，为了您和儿童可以更加便捷地访问、更正、删除相关个人信息，我们在产品设计中为您提供了相应的操作设置，您也可以通过我们提供的联系方式，来反馈和咨询相关信息。我们会在核实您反馈的问题后及时与您联系。请您理解，在核实过程中，我们可能需要您提供儿童账号、儿童出生日期、联系方式，以便在完成身份验证和问题核实后继续响应您的需求。</p>
                    <ul>
                        <li>访问儿童个人信息</li>
                        <p>您和您监护的儿童可以查看儿童用户在使用我们产品或服务中提供或产生的个人信息，包括但不限于用户名、密码、头像、昵称、性别、主动发布的内容。</p>
                        <li>更正儿童个人信息</li>
                        <p>当您和您监护的儿童发现我们收集、使用和处理的儿童个人信息有错误时，可以联系我们进行更正。我们会在完成身份验证和核实问题后，及时采取措施予以更正。</p>
                        <li>删除儿童个人信息</li>
                        <p>如存在以下情况，您和您监护的儿童可以请求我们删除收集、使用和处理的儿童个人信息。我们会在完成身份验证和核实问题后，及时采取措施予以删除。</p>
                    </ul>
                    <p>1）若我们违反法律、行政法规的规定或者双方的约定收集、存储、使用、转移、披露儿童个人信息；</p>
                    <p>2）若我们超出目的范围或者必要期限收集、存储、使用、转移、披露儿童个人信息；</p>
                    <p>3）您撤回同意；</p>
                    <p>4）您监护的儿童通过注销等方式终止使用产品或者服务的。</p>
                    <p>但请注意，若您和被监护儿童要求我们删除特定儿童个人信息，可能导致该儿童用户无法继续使用我们的产品和服务或产品和服务中的某些具体业务功能。</p>
                    <p>如您发现在未事先征得您同意的情况下收集了您儿童的个人信息，请及时联系我们，我们会设法尽快删除相关数据。</p>
                    <p style={{ fontWeight: 600, }}>儿童何时可以自己管理账号</p>
                    <p>被监护儿童年满14周岁后，有权自行管理其账号。如果儿童用户选择自行管理其游戏搭子账号，您将无法继续访问或控制其账号。当被监护儿童有权自行管理游戏搭子账号时，我们会通知您和被监护儿童。</p>
                    <p style={{ fontWeight: 600, }}>本声明的适用</p>
                    <p>本声明仅适用于我们专门面向14周岁以下的儿童提供的产品或服务或部分功能板块的产品或服务，或者需要用户输入生日、年龄信息以便识别用户年龄的产品或服务，针对不能识别或不主动识别用户年龄的产品或服务，仅适用《游戏搭子隐私保护指引》。</p>
                    <p>请您理解，如本声明未规定之处，参照适用《游戏搭子隐私保护指引》。</p>
                    <p>在适用本声明的情况下，如本声明与《游戏搭子隐私保护指引》的条款规定存在不一致的，以本声明为准。</p>
                    <p style={{ fontWeight: 600, }}>联系我们</p>
                    <p>如您对本声明有任何疑问和反馈，您可通过以下方式与我们取得联系：</p>
                    <p>联系人：游戏搭子客服</p>
                    <p>联系邮箱：daziapp@163.com</p>
                    <p style={{ marginBottom: '25px', display: 'inline-block' }}>我们将尽快审核所涉问题，并在验证您身份后的十五个工作日内予以回复。</p>
                </div>
            </div>
        </div>
    );
}

export default PrivacyAgreement;
