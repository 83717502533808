import React, { useEffect, useState } from "react";
import VConsole from "vconsole";
import '../css/QueueCarnival.css'
import queue_bg from "../images/QueueCarnival/首图@2x.png";
import queue_btn1 from "../images/QueueCarnival/魅力榜_bg@2x.png";
import queue_week from "../images/QueueCarnival/本周榜单.webp";
import queue_charm from "../images/QueueCarnival/魅力奖励榜单@2x.png";
import queue_contribute1 from "../images/QueueCarnival/贡献榜_bg@2x.png"
import queue_contribute2 from "../images/QueueCarnival/贡献榜_榜单@2x.png"
import queue_nav from "../images/QueueCarnival/导航_bg@2x.png";

function QueueCarnival() {
     document.title = '派对狂欢榜'

     // useEffect(() => {
     //      new VConsole();
     // }, []);

     const weekClick=()=>{
          console.log(111);

          const sendMessageToFlutter = () =>{
               if (window.Activity) {
                    window.Activity.postMessage("dazi://jumpToRankList");
               } else {
                    console.error("FlutterChannel is not available.");
               }
               
          };
          sendMessageToFlutter()
     }

     return (
          <>
               <div className="queue_bg">
                    <img src={queue_bg} alt="" style={{ width: "100%" }} />
                    <p className="p1">每周一0:00至周日23:59:59</p>
                    <img src={queue_btn1} alt="" className="img1" />
               </div>

               <img src={queue_week} alt="" className="queue_week" onClick={weekClick}/>

               <div className="queue_one">
                    <img src={queue_charm} alt="" className="queue_charm" />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '4%', marginBottom: '3%' }}>
                         <img src={queue_contribute1} alt="" className="queue_contribute1" />
                    </div>
                    <img src={queue_contribute2} alt="" className="queue_contribute2" />
               </div>

               <div className="queue_two" style={{ paddingBottom: '4%' }}>
                    <img src={queue_nav} alt="" className="img1" />
                    <p className="p1">活动规则</p>
                    <div className="queue_two_one">
                         <p className="p2">派对狂欢榜分为魅力榜单与贡献榜单，每自然周零点结算，魅力值/贡献值越高，榜单排名越靠前</p>
                         <p className="p3">魅力榜:1钻石=1魅力值</p>
                         <p className="p4">贡献值:1钻石=1贡献值</p>
                         <p>可前往【首页】右上角的【榜单】查看自己的排名</p>
                    </div>
               </div>
               <div className="queue_two">
                    <img src={queue_nav} alt="" className="img1" style={{top:'-0.5%'}}/>
                    <p className="p1" style={{ top: '0%' }}>平台说明</p>
                    <div className="queue_two_one">
                         <p className="p5" style={{ marginTop: '5%' }}>1、每周结榜时，该奖励直接下发，可前往我的装扮页面进行佩戴</p>
                         <p className="p5">2、严禁任何影响妨害游戏搭子健康运营的行为包括但不限于发布引流广告、展开黄赌毒等违规违法犯罪活动;严禁任何礼物、游戏豆、魔法帽等虚拟资源的交易行为;严禁诱导未成年人打赏;否则，平台将根据法律法规及平台规则严厉处置，由此造成的损失及责任均由账号持有人承担。</p>
                         <p className="p5">3、用户在参与活动过程中请遵守平台规则，严禁任何影响平台运营、影响活动公平性及用户体验的行为，否则游戏搭子有权采取措施限制部分或全部账号功能或永久封号;若涉及虚拟物品或虚拟道具的，游戏搭子有权取消发放或追回。</p>
                         <p className="p5" style={{marginBottom:0}}>4、若用户违反法律法规、政策或平台规则的，游戏搭子有权依法向主管机关报告并提交用户注册信息、使用记录及其他信息，并可将其对应的行为和平台处理措施在游戏搭子平台予以公示。同时，游戏搭子有权对以上用户进行黑名单管理，对黑名单用户采取包括但不限于禁止重新注册账号等措施。</p>
                    </div>
               </div>
          </>
     )
}

export default QueueCarnival;
