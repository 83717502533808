import React from "react";

function CaptainsAgreement() {

     document.title = '游戏搭子队长协议'

     return (

          <div style={{ width: '100%' }}>

               <div style={{ width: '90%', margin: '5px auto', fontSize: '15px' }}>

                    <h2 style={{ textAlign: 'center', fontWeight: 500, fontSize: '15px' }}>生效日期：2024.11.08</h2>


                    <h3 style={{ margin: '10px 0', fontSize: '15px' }}>【声明】</h3>
                    <p >游戏搭子平台没有委托任何第三方进行“队长”业务进行线下代理。对于未经授权的代理机构、个人所销售商品或承诺服务，游戏搭子平台将不承担任何法律责任。</p>
                    
                    
                    <h3 style={{ margin: '10px 0', fontSize: '15px' }}>总则</h3>
                    <div style={{ lineHeight: '20px' }}>
                         <p>本协议由天津搭子科技有限公司或其关联公司(下称“游戏搭子”'或“我方”)及同意并承诺遵守本协议规定使用“队长”服务的自然人、法人或非法人组织(下称“队长”或“你方”)共同缔结，本协议具有合同效力。你方勾选“我同意”或点击“我已阅读并遵守该协议”按钮，即表示你方已经仔细阅读、充分理解并完全地毫无保留地接受本协议的所有条款。</p>
                         <p>签署本协议前，你方已充分了解我方之各项规则及要求，且有条件及有能力、资格履行本协议约定的作为队长的职责及义务。本协议对你方构成有效的、带有约束力的、可强制执行的法定义务，你方对本协议下所有条款及定义等内容均已明确知悉，并无疑义。</p>
                         <p>你方承诺并声明在为我方提供服务时符合所在地法律的相关规定，不得以履行本协议名义从事其他违反中国及所在地法律规定的行为。</p>
                         <p>你方除遵守本协议外，还需遵守游戏搭子平台的用户协议。</p>
                    </div>
                    
                    
                    <div style={{fontSize:'14px',lineHeight:'22px'}}>
                    
                         <p style={{ margin: '10px 0', fontWeight: 600, fontSize: '15px', }}>一、合作内容</p>
                         <ul style={{ listStyle: 'none'}}>
                              <li>1.1你方可利用我方研发运营的游戏搭子队长服务平台(包括但不限于游戏搭子手机端APP及其PC网页、客户端)发布你方作为队长的技能资质信息，包括但不限于真实的身份信息、个人资料、营业资料等。</li>
                              <li>1.2你方可通过游戏搭子平台认证成为平台队长，发布个人技能资质信息，并销售服务、通过带领平台用户打游戏等而获得用户打赏等行为而获取收益。你方在游戏搭子平台上的全部行为仅代表其个人或其公司，不代表游戏搭子平台，基于该行为所产生的全部法律责任由你方自行承担。</li>
                              <li>1.3合作时间：本协议经队长在线接受且经过游戏搭子平台审核通过后即告生效，除非本协议规定的终止条件发生，本协议将持续有效。双方另有约定的除外。</li>
                              <li>1.4队长服务：你方应在线提交资质审核申请成为队长，若不符合游戏搭子平台《队长资质审核规范》的，我方有权驳回开通请求。你方通过游戏搭子平台出售技能、提供服务，通过带领平台用户打游戏等而获得用户打赏等行为而获取收益。使用游戏搭子平台服务与用户在平台进行交易，平台有权按照你方在平台交易额的一定比例或与平台约定达成的一定金额收取相应费用。</li>
                              <li>1.5交易：指用户通过游戏搭子平台购买队长服务，和通过带领平台用户打游戏等而获得用户打赏等。</li>
                              <li>1.6队长收益：你方与我方不构成任何劳动法律层面的雇佣、劳动、劳务关系，我方无需向你方支付社会保险金和福利。你方履行队长服务的收益由平台用户提供。任何时候，你方自游戏搭子平台获取的收益. (若有)，不应当视为工资、薪金及劳务报酬或类似收入。你方提供队长服务，和通过带领平台用户打游戏等而获得用户打赏所获得的收益（平台扣除一定比例的费用后）可申请队长收入提现。你方同意游戏搭子平台有权根据平台运营能力和成本设置提现额度的档位和费用，具体以提现页面展示为准，我方更改提现额度和费用的，将提前七天在提现页面予以公示并通知你方。用户每次提现时若符合平台设定的提现额度档位则可申请提现，若不符合，剩余金额可在下次满足前述提现额度时再申请提现。</li>
                              <li>1.7定价权：你方应遵守“游戏搭子”平台内的定价规则，不得擅自对“游戏搭子”内的定价规则进行任何形式的更改，你方不得以任何形式绕开“游戏搭子”平台内定价规则进行线上或线下交易，亦不得通过私下交易的方式逃避“游戏搭子”平台内定价规则。若你方违反游戏搭子平台规则进行线下交易的，我方有权依据本协议第三条对你方进行违约处理。</li>
                              <li>1.8侵权纠纷：你方应遵守相关法律法规及游戏搭子平台规则，不得侵犯用户及第三方的权利。如用户或第三方(此处称“投诉人”)向游戏搭子平台投诉你方存在侵权行为的，我方有权根据法律、法规、平台规则和投诉人，的要求，即时采取包括但不限于在相关页面公示信息、删除、屏蔽、断开链接、暂停服务、终止交易和服务等必要措施予以处理，我方处理后将及时通知你方。你方同意并理解，游戏搭子采取前述措施系为防止潜在侵权结果的扩大，并不代表游戏搭子主动或有义务认定你方行为的性质，游戏搭子无须承担你方因此可能遭受的损失。</li>
                              <li>1.9 你方不能从事任何行为的套现行为，一经发现平台可以单方面解除协议，并封队长的账号。</li>
                              <li>1.10 你方如果发现平台bug等对平台不利的行为，有义务告知我方，你方不管在任何情况下不能利用平台bug去获取平台的利益，从而造成平台的损失。因为平台bug你方获得的利益，我方保留随时调整和追回的权利。</li>
                              <li>1.11合作变更：我方有权对游戏搭子平台相关规则及本协议内容进行不时的修订，并将提前7日予以公示并通知你方，你方若不予接受的应当立即停止使用游戏搭子平台并收到我方通知之日起7日内提出，我方将按照本协议第六条予以处理，否则即视为你方接受前述修订。</li>
                         </ul>

                         
                         <p style={{ margin: '10px 0', fontWeight: 600, fontSize: '15px', }}>二、我方权利与义务</p>
                         <ul style={{ listStyle: 'none'}}>
                              <li>2.1 我方保证你方经审核通过的队长信息在游戏搭子平台上能正常显示，包括队长个人技能资质信息、点赞信息、礼物信息、评论信息等。</li>
                              <li>2.2我方可以为你方提供交易的平台，你方可以通过游戏搭子平台出售技能、提供服务，使用游戏搭子平台服务与用户在平台进行交易。</li>
                              <li>2.3当第三方通知我方，认为你方在平台上发布的信息存在重大问题时，我方将以普通非专业人员的知识水平标准对相关内容或行为进行初步判别，如判别认定该内容或行为不当或具有违法性质，我方有权删除相关信息或/和停止对你方提供服务，该等情形包括但不限于如下：</li>
                              <li>(1)只有透露第三方用户的个人资料，才能提供用户所要求的产品和服务；</li>
                              <li>(2)根据有关的法律法规要求，存在违法行为的；</li>
                              <li>(3)按照相关政府主管部门的要求；</li>
                              <li>(4)为维护游戏搭子平台的合法权益；</li>
                              <li>2.4你方同意，在你方注册游戏搭子平台账户，使用游戏搭子平台提供的产品或服务，游戏搭子平台会收集你方的身份识别资料或营业登记资料等，并会将这些资料用于：通过游戏搭子向用户公开、建立信息登记档案并定期核实更新、改进为你方提供的服务及信息内容，或应政府部门的要求向其报送(如需)。</li>
                              <li>2.5违规行为的认定与处理，应基于交易双方向游戏搭子平台阐述的事实及提交的佐证材料，以及游戏搭子平台通过其他途径收集的证据材料予以认定，一旦队长违规行为被认定，则游戏搭子平台有权根据认定的事实严格依规实施违规处理措施。游戏搭子平台所有用户在适用本规则上一律平等。</li>
                              <li>2.6游戏搭子有权对用户行为及适用的规则进行单方认定，并据此处理。</li>
                              <li>2.7我方平台严禁队长一人多号，如果你方希望注册或认证新的队长账号，请务必告知我方平台注销旧账号队长资质。我方平台会通过大数据和算法，不定期查询队长是否存在一人多号的嫌疑，如果我方平台通过证据（包括但不局限于IP地址，手机号，手机唯一码，登录时间，用户行为相似度等）确认存在一人多号的情况，我们有权在不通知你方的情况下，冻结或者封停账号</li>
                         </ul>


                         <p style={{ margin: '10px 0', fontWeight: 600, fontSize: '15px', }}>三、你方权利与义务</p>
                         <ul style={{ listStyle: 'none'}}>
                              <li>3.1资质文件提交： 你方欲使用本协议下服务，必须向我方提交如下文件(以下简称“资质文件”包括但不仅限身份证，具体按照申请流程走) ：你方出具个人技能资质(需通过图片上传，图片内具有资质所需信息) ；</li>
                              <li>3.2平台服务：你方完整、准确提交上述资质文件并经我方审核通过后，我方即为你方开通平台服务。开通后，你方可在平台上发布队长信息，经我方审核通过后即可显示在平台上。你方应妥善保管其账号及密码信息，不得以任何形式擅自转让或授权他人使用，否则须自行承担由此引起的法律责任。</li>
                              <li>3.3平台合法使用：</li>
                              <li>(1) 遵守国家法律法规：你方保证在使用我方服务时实施的所有行为均遵守国家法律、法规以及社会公共利益。如有违反导致任何法律后果的发生，你方将以自己的名义独立承担相应的法律责任。如因此给我方或关联方、合作方造成损失或因此导致我方或关联方、合作方承担连带责任的，我方或关联方、合作方有权向你方追偿。</li>
                              <li>(2) 不得侵害第三方合法权益：你方承诺拥有合法的权利和资格向平台用户提供服务，且前述行为未对任何第三方合法权益，包括但不限于第三方知识产权、物权等构成侵害，如你方因违反上述约定而引|起的任何第三方提起的投诉、索赔，诉讼或行政责任的均与我方无关，因此造成我方损失的则由你方全部赔偿。</li>
                              <li>3.4信息变更的通知：你方在本协议期限内如变更或新增任何信息(包括但不限于资质文件及其所含信息、个人资料及身份信息等)，应保证在一个工作日内通知我方并更新至平台上。</li>
                              <li>
                                   (1) 资质信息变更：协议期内，资质文件及其所含信息发生任何变更，你方应重新编辑技能资质信息并重新申请成为队长，如资质文件及其所含信息的变更使得你方不再具备成为队长履行本协议的情形出现时，我方有权立即终止本协议，不承担任何赔偿及法律责任。你方同
                                   意为其未在约定时间内通知或更新其信息承担全部法律责任。
                              </li>
                              <li>(2) 个人资料及身份信息变更：如游戏、等级、角色等游戏资质信息需变更，你方可在线进行修改，在经我方审核通过后，我方承诺在3个工作日内完成平台上显示信息的变更。</li>
                              <li>3.5信息真实、准确、合法、有效性承诺：你方保证其向我方提供的全部信息，包括但不限于证明文件、个人资料、公司资料及身份信息等，真实、准确、合法，且在协议履行期间都处于合法有效期内，不可存在任何误导、遗漏、虚假或隐瞒，否则，因此给第三方和/或我方带来损失的，应由你方独立承担全部法律责任并予以赔偿。</li>
                              <li>3.6游戏搭子平台禁止线下交易，若你方出现线下交易违规行为的，我方有权对你方身份信息所绑定的所有游戏搭子账号进行暂停使用、查封、冻结或清空虚拟货币、注销等处理。用户因线下交易造成的一切经济损失由用户自行承担，游戏搭子将不承担任何责任。</li>
                              <li>3.7你方应当自行约束你方在游戏搭子平台上的用户行为，保证自己提供服务过程中的人身安全及财产安全。如你方在游戏搭子平台网站外的场所或地域，因你方自身原因、不可抗力或意外事件或第三方原因造成的人身及财产损失，由你方自行承担。</li>
                              <li>3.8使用游戏搭子平台服务过程中，你方需遵守以下法律法规：《中华人民共和国保守国家秘密法》 、《中华人民共和国著作权法》、《中华人民共和国电子商务法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《信息网络传播权保护条例》、《网络交易管理办法》 、《互联网直播服务管理规定》等有关计算机及互联网规定的法律、法规。在任何情况下，游戏搭子一旦合理地认为你方的行为可能违反.上述法律、法规，可以在任何时候，不经事先通知终止向你方提供服务。</li>
                              <li>3.9你方同意并保证，不得利用游戏搭子平台服务或其衍生物进行倒卖、转手、置换、抵押有价交易等方式非法牟利。您不会利用游戏搭子平台服务或其衍生物侵犯他人的合法权益，禁止通过网络漏洞、恶意软件或其他非法手段窃取、盗用他人的账户、虚拟货币等。</li>
                         </ul>

                         <p style={{ margin: '10px 0', fontWeight: 600, fontSize: '15px', }}>四、解约</p>
                         <ul style={{ listStyle: 'none'}}>
                              <li>4.1你方同意赔偿由 于使用我方服务(包括但不限于将你方资料展示在互联网上)或违反本协议而给我方或关联方、合作方造成的任何损失(包括由此产生的全额的诉讼费用和律师费)。你方同意我方不对任何由你方张贴的资料承担任何责任；由于此类资料对其它用户造成的损失由你方自行全部承担。</li>
                              <li>4.2除本协议另有约定之外，如一方发生违约行为，守约方可以书面通知方式(根据你方预留于游戏搭子平台的联系方式发出的纸质文件、短信或电子邮件、平台通知、公示文案、站内消息、弹出消息、客户端推送的消息等)要求违约方在指定的时限内停止违约行为，并就违约行为造成的损失进行索赔，如违约方未能按时停止违约行为，则守约方除就其损失获得所有赔偿外亦有权立即终止本协议。</li>
                              <li>4.3不可抗力免责条款：如由于不可抗力事件，包括但不限于互联网连接故障、电脑、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、骚乱、火灾、洪水、风暴、爆炸、战争、政府行为、法院的命令或第三方的不作为或任何其他类似事件，致使合同的部分或全部不能履行或延迟履行，则我方不承担任何违约责任，但双方应将不可抗力所造成的影响控制在最小范围内。</li>
                         </ul>


                         <p style={{ margin: '10px 0', fontWeight: 600, fontSize: '15px', }}>五、法律适用及解决争议的方式</p>
                         <ul style={{ listStyle: 'none'}}>
                              <li>5.1 法律适用：本协议的订立、效力、履行和解释及争议的解决适用中华人民共和国法律、法规和计算机、互联网行业的规范。合同条款如与相关法律、法规内容抵触，该条款无效，但不影响其他条款的法律效力。</li>
                              <li>5.2解决争议的方式：因本协议订立、解释和履行发生的争议，双方应友好协商解决，如协商不成，提交北京仲裁委员会仲裁解决。仲裁应依据该仲裁委员会当时的仲裁规则进行；仲裁裁决是终局的，对双方都有约束力；除非仲裁裁决另有裁定，仲裁费用由败诉方承担。</li>
                         </ul>


                         <p style={{ margin: '10px 0', fontWeight: 600, fontSize: '15px', }}>六、合同终止</p>
                         <ul style={{ listStyle: 'none'}}>
                              <li>6.1提前通知解除： 游戏搭子平台可基于任何原因随时单方终止本协议，通知以书面通知(根据你方预留于游戏搭子平台的联系方式发出的纸质文件、短信或电子邮件、平台通知、公示文案、站内消息弹出消息、客户端推送的消息等)的方式提前终止本协议。我方通过上述方式向你方发出通知，在发送成功后即视为送达，即对队长具有法律约束力，我方将据实结算队长已经发生的费用，除此之处，我方无需承担任何违约责任。</li>
                              <li>6.2协议终止后有 关事项的处理：</li>
                              <li>(1) 协议终止后，我方有权删除你方平台服务账户和/或与之相关的任何信息，有权终止转发任何未曾阅读或发送的信息给你方或第三方，我方亦不就终止协议而对你方或任何第三方承担任何责任；</li>
                              <li>(2)协议终止后，我方有权保留你方的注册数据及以前的相关行为记录。如你方在协议终止前在平台，上存在违法行为或违反协议的行为，我方仍可行使本协议所规定的权利；</li>
                              <li>(3)协议终止之前，你方已经接取的服务尚未完成实现的，游戏搭子有权在协议终止时同时删除与此相关的信息，同时该部分费用不予退还。</li>
                         </ul>


                         <p style={{ margin: '10px 0', fontWeight: 600, fontSize: '15px', }}>七、保密条款</p>
                         <ul style={{ listStyle: 'none'}}>
                              <li>7.1 本协议所称商业秘密包括但不限于本协议、任何补充协议所述内容及在合作过程中涉及的其他秘密信息。任何一方未经商业秘密提供方书面同意，均不得将该信息向第三方(有关法律、法规、政府部门、证券交易所或其他监管机构要求和双方的法律、会计、商业及其他顾问、雇员除外)披露、传播、编辑或展示。</li>
                              <li>7.2任何一方违反本保密条款，需赔偿守约方全部直接和间接损失。</li>
                              <li>7.3 该商业秘密已为公众所知悉的，披露方不承担责任。</li>
                              <li>7.4本协议终止后， 双方仍承担保密条款下的相关义务，保密期将另外持续三年。</li>
                         </ul>


                         <p style={{ margin: '10px 0', fontWeight: 600, fontSize: '15px', }}>八、其它</p>
                         <ul style={{ listStyle: 'none'}}>
                              <li>8.1你方理解网站或客户端为正常运行需进行停机维护，如因此造成你方信息不能按计划发布，你方承诺不追究我方法律责任，但我方有义务尽力避免服务中断或将中断限制在最短时间内。</li>
                              <li>8.2 由于黑客攻击、第三方公司软件屏蔽或非因我方技术原因导致你方信息无法展示的，你方对此予以理解，并承诺不追究我方责任，但我方尽可能将上述影响减少到最低。</li>
                              <li>8.3任何由于你方声明不实或违反其声明承诺事项导致他方向我方提起诉讼、仲裁索赔及/或导致我方声誉受损之后果，你方将承担我方因此产生的全部直接及间接费用、损失及赔偿，其中包括我方为诉讼/仲裁支付的有关费用及律师费。</li>
                              <li>8.4除本协议 另有约定外，你方违反本协议下任何规定或你方提供的服务不符合我方的要求，我方有权单方面采取相应限制或处罚措施，包括但不限于：限制、关闭、回收、或终止你方队长账号的使用，限制或停止某项单独服务(如打赏)并根据实际情况决定是否恢复使用，扣除你方账号中尚未结算的服务费用。</li>
                         </ul>


                         <p style={{ margin: '10px 0', fontWeight: 600, fontSize: '15px', }}>九、法律适用与管辖</p>
                         <p>本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生之争议,均应依照中华人民共和国法律予以处理,双方应首先友好协商,若在争议发生后协商不成,应将争议提请北京仲裁委员会照该会仲裁规则进行仲裁解决，仲裁是终局的，对各方均有约束力。</p>
                         
                         
                         <p style={{ margin: '10px 0', fontWeight: 600, fontSize: '15px', }}>十、特别声明</p>
                         <p>天津搭子科技有限公司在此依法做出特别声明：</p>
                         <p>如我们采取合理的方式提请用户注意的义务将通过如下方式实现:在本协议中我们以明确的足以引起用户注意的加重字体、斜体、下划线、颜色标记等合理方式提醒用户注意相关条款(需要强调的是,还包括用户应特别注意任何未明确标记的含有“不承担”、“免责”、“不得”等形式用语的条款),该等条款的确认将导致用户在特定情况下的被动、不便、损失,请用户在确认同意本协议之前再次阅读上述条款。</p>
                         <p>用户如有任何需要说明条款的要求,请立即停止使用服务, 同时立即发送邮件至daziapp@163.com,若用户未致电或发送有说明条款要求的信息至天津搭子科技有限公司而选择同意该协议,则双方在此确认我们已依法履行了根据用户要求对相关条款进行说明的法定义务,我们已给予用户充足的时间与充分的选择权来决定是否缔结本协议。</p>
                         <p style={{ marginBottom: '25px', display: 'inline-block' }}>鉴于我们已依法明确了上述条款、履行了格式条款制订方的义务,用户点击同意或下一步,将被视为且应当被视为用户已经完全注意并同意了本协议所有条款尤其是提醒用户注意的条款的合法性及有效性,用户不应当以我们未对格式条款以合理方式提醒用户注意,或未根据用户要求尽到说明义务为理由而声称或要求法院或其它任何第三方确认相关条款非法或无效.</p>
                    </div>

               </div>



          </div>
     )
}

export default CaptainsAgreement;
