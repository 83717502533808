import React, { useEffect, useState } from "react";
import "../css/WinterPromise.css";
import winter_bg from "../images/WinterPromise/top_tu@2x.png";
import winter_btn from "../images/WinterPromise/按钮@2x.png";
import winter_time from "../images/WinterPromise/时间_bg@2x.png";
import winter_heart from "../images/WinterPromise/爱心板块.webp";
import winter_nv from "../images/WinterPromise/title_gb@2x.png";
import winter_nv1 from "../images/WinterPromise/title_gb_女神@2x.png";
import winter_nan from "../images/WinterPromise/title_gb骑士@2x.png";
import winter_nan1 from "../images/WinterPromise/title_gb_骑士_点亮@2x.png";
import winter_goodbg from "../images/WinterPromise/top_奖励bg@2x.png";
import winter_goodbg2 from "../images/WinterPromise/男_top_奖励@2x.png";
import winter_girlGift from "../images/WinterPromise/女生礼物@2x.png";
import winter_girlGift2 from "../images/WinterPromise/男生礼物@2x.png";
import winter_menu from "../images/WinterPromise/菜单栏_bg@2x.png";
import winter_default from "../images/WinterPromise/红色座位.png";
import winter_more from "../images/WinterPromise/查看更多_bg.png";
import winter_more2 from "../images/WinterPromise/查看更多_男.png";
import winter_top1 from "../images/WinterPromise/01@2x.png";
import winter_title_gb from "../images/WinterPromise/title_gb@2x1.png";
import winter_redHeart from "../images/WinterPromise/红心@2x(2).png";
import winter_tan from "../images/WinterPromise/秋日之约_弹窗.webp";
import winter_bian from "../images/WinterPromise/编组@2x.png";
import Api from "../utils/Api";
import { Toast } from '@douyinfe/semi-ui';
import VConsole from "vconsole";

function WinterPromise() {
     document.title = '冬日之约'

     const [token, setToken] = useState();
     window.addEventListener("message", (event) => {
          console.log("Received event: ", event);
          const msg = event.data;
          console.log("Received message:", event.data);
          if(msg){
               try {
                    const cc = JSON.parse(msg);
                    const ticket = cc['aite-token'];
                    sessionStorage.setItem("apptk", ticket);
                    setToken(ticket);
               }catch (e){
                    console.warn("Get bridge msg error, ", e);
               }
          }
     });

     useEffect(() => {
         // new VConsole();
          return () => <></>;
     }, []);

     const [isNv, setisNv] = useState(true);//女神榜
     const [isNan, setisNan] = useState(false);//骑士榜
     const [isRule, setIsRule] = useState(false);//活动说明
     const [isMore, setIsMore] = useState(false);//查看更多
     const [isFirstVisit, setIsFirstVisit] = useState(false);//首次进入
     const [isNvList, setisNvList] = useState([]);//女神榜
     const [isNanList, setisNanList] = useState([]);//骑士榜
     const [isHourList, setisHourList] = useState([]);//小时榜
     const [isNvfooter, setisNvfooter] = useState([]);//女神榜底部
     const [isNanfooter, setisNanfooter] = useState([]);//骑士榜底部
     const [isTime, setisTime] = useState([]);//时间

     useEffect(() => {
          getnvList()
          getnanList()
          hourList()
     }, [token]);

     const data = [
          {
               id: '01',
               moName: '虚位以待',
               amount: '0'
          },
          {
               id: '02',
               moName: '虚位以待',
               amount: '0'
          },
          {
               id: '03',
               moName: '虚位以待',
               amount: '0'
          },
          {
               id: '04',
               moName: '虚位以待',
               amount: '0'
          },
          {
               id: '05',
               moName: '虚位以待',
               amount: '0'
          },
          {
               id: '06',
               moName: '虚位以待',
               amount: '0'
          },
          {
               id: '07',
               moName: '虚位以待',
               amount: '0'
          },
          {
               id: '08',
               moName: '虚位以待',
               amount: '0'
          },
          {
               id: '09',
               moName: '虚位以待',
               amount: '0'
          },
          {
               id: '10',
               moName: '虚位以待',
               amount: '0'
          },
     ];

     const getnvList = () => Api.nvList().then((res) => {//女神榜
          if (res.data.code == 200) {
               const firstTwentyNv = res.data.data.list.slice(0, 20);
               setisNvList(firstTwentyNv)
               setisNvfooter(res.data.data.myRanking)
               
               const onlineTime = res.data.data.activityTheme.onlineTime;
               const endTime = res.data.data.activityTheme.endTime;
               const onlineDate = new Date(onlineTime);
               const endDate = new Date(endTime);
               const onlineMonth = onlineDate.getMonth() + 1; // 月份从0开始，所以加1
               const onlineDay = onlineDate.getDate();
               const endMonth = endDate.getMonth() + 1;
               const endDay = endDate.getDate();
               const newFormat = `${onlineMonth}月${onlineDay}日0:00 - ${endMonth}月${endDay}日23:59`;
               setisTime(newFormat)

               if (res.data.showPopup){
                    setIsFirstVisit(false);
               }else{
                    setIsFirstVisit(true);
               }
          }
     });

     const getnanList = () => Api.nanList().then((res) => {//骑士榜
          if (res.data.code == 200) {
               const firstTwentyNan = res.data.data.list.slice(0, 20);
               setisNanList(firstTwentyNan)
               setisNanfooter(res.data.data.myRanking)
          }
     });

     const hourList = () => Api.hourList().then((res) => {//小时榜
          if (res.data.code == 200) {
               setisHourList(res.data.data.list[0])
          }
     });

     const nvClick = () => {//女神榜
          setisNv(true);
          setisNan(false);
     };

     const nanClick = () => {//骑士榜
          setisNan(true);
          setisNv(false);
          console.log(isNvList);
          
     };

     const ruleClick = () => {//活动说明
          setIsRule(!isRule);
     };

     const bgClick = () => {//点击背景关闭弹窗
          setIsRule(false);
          setIsMore(false);
          setIsFirstVisit(false)
     };

     const moreClick = () => {//查看更多
          setIsMore(!isMore);
     };

     const furlClick = () => {//开心收下
          Api.furl().then((res) => {
               if (res.data.code == 200) {
                    Toast.success('领取成功')
                    setIsFirstVisit(false);
               }
          });
     };
     
     return (
          <>
               <div className="winter_bg">
                    <img src={winter_bg} alt="" style={{ width: "100%" }} />
                    <img src={winter_time} alt="" style={{ width: "90%", }} className="winter_time_Img" />
                    <p className="winter_time_p">{isTime}</p>
                    <p className="winter_rule" onClick={ruleClick}>活动规则</p>
               </div>

               {isHourList == undefined ? (
                    <div className="winter_one">
                         <img src={winter_heart} alt="" className="winter_one_Img" />
                         <p className="winter_one_p2">告白头条</p>

                         <div className="winter_one_box">

                              <div style={{ width: '40%', textAlign: 'center' }}>
                                   <img src={winter_default} alt="" className="winter_one_Imgheard" />
                                   <p className="winter_one_p3">拭目以待</p>
                              </div>
                              <div style={{ width: '40%', textAlign: 'center' }}>
                                   <img src={winter_default} alt="" className="winter_one_Imgheard2" />
                                   <p className="winter_one_p4">拭目以待</p>
                              </div>
                         </div>
                         <p className="winter_one_p">人气值： 拭目以待</p>
                    </div>
               ) : (
                    <div className="winter_one">
                         <img src={winter_heart} alt="" className="winter_one_Img" />
                         <p className="winter_one_p2">告白头条</p>

                         <div className="winter_one_box">

                              <div style={{ width: '40%', textAlign: 'center' }}>
                                   <img src={isHourList.fromUserAvatar} alt="" className="winter_one_Imgheard" />
                                   <p className="winter_one_p3">{isHourList.fromUserName}</p>
                              </div>
                              <div style={{ width: '40%', textAlign: 'center' }}>
                                   <img src={isHourList.toUserAvatar} alt="" className="winter_one_Imgheard2" />
                                   <p className="winter_one_p4">{isHourList.toUserName}</p>
                              </div>
                         </div>
                         <p className="winter_one_p">人气值 {isHourList.amount}</p>
                    </div>
               )}

               <div className="winter_two">
                    {
                         isNv && (
                              <div className="winter_two_box" style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-13%', borderTopLeftRadius: '45px', borderTopRightRadius: '45px' }}>
                                   <img src={winter_nv} alt="" className="winter_one_Img2" />
                                   <p className="winter_time_p2" onClick={nvClick} style={{ color: isNv ? '#FFF' : 'rgb(205, 151, 213)', }}>女神榜</p>
                                   <img src={winter_nan} alt="" className="winter_one_Img2" />
                                   <p className="winter_time_p3" onClick={nanClick} style={{ color: isNv ? 'rgb(205, 151, 213)' : '#FFF', }}>骑士榜</p>
                              </div>
                         )
                    }

                    {
                         isNan && (
                              <div className="winter_two_box" style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-13%', borderTopLeftRadius: '45px', borderTopRightRadius: '45px' }}>
                                   <img src={winter_nv1} alt="" className="winter_one_Img2" />
                                   <p className="winter_time_p2" onClick={nvClick} style={{ color: isNv ? '#FFF' : 'rgb(205, 151, 213)', }}>女神榜</p>
                                   <img src={winter_nan1} alt="" className="winter_one_Img2" />
                                   <p className="winter_time_p3" onClick={nanClick} style={{ color: isNv ? 'rgb(205, 151, 213)' : '#FFF', }}>骑士榜</p>
                              </div>
                         )
                    }
               </div>



               {isNv && (
                    <>
                         <div className="winter_three">
                              <p className="winter_two_p">总TOP5奖励</p>
                              <img src={winter_goodbg} alt="" className="winter_two_bg" />
                              <p className="winter_two_p2">收任意礼物</p>
                              <p className="winter_two_p3">1钻=100人气值</p>
                              <img src={winter_girlGift} alt="" className="winter_two_img" />
                              <img src={winter_more} alt="" className="winter_two_img2" />
                              <p className="winter_two_p4" onClick={moreClick}>查看更多</p>
                         </div>

                         <div className="winter_four">
                              {isNvList.length === 0 ? (
                                   data.map((item, index) => {
                                        return (
                                             <div className="winter_four_one" key={index}>
                                                  <div className="winter_four_one_left">
                                                       <img src={winter_top1} alt="" className="winter_four_one_img1" />
                                                       <p className="winter_four_one_p1" style={{ marginLeft: '-18%' }}>{item.id}</p>
                                                       <img src={winter_default} alt="" className="winter_four_one_img2" />
                                                       <img src={winter_default} alt="" className="winter_four_one_img3" />
                                                  </div>

                                                  <div className="winter_four_one_center">
                                                       <p style={{ fontSize: '3vw' }}>{item.moName}</p>
                                                  </div>
                                                  <div className="winter_four_one_right">
                                                       <p style={{ fontSize: '3.5vw' }}>{item.amount}</p>
                                                       <p style={{ color: '#c1b2b0', fontSize: '3vw' }}>人气值</p>
                                                  </div>

                                             </div>
                                        )
                                   })
                              ) : (
                                   isNvList.map((item, index) => {
                                        return (
                                             <div className="winter_four_one" key={index}>
                                                  <div className="winter_four_one_left">
                                                       <img src={winter_top1} alt="" className="winter_four_one_img1" />
                                                       <p className="winter_four_one_p1">{item.id}</p>
                                                       <img src={item.toUserAvatar} alt="" className="winter_four_one_img2" />
                                                       <img src={item.fromUserAvatar} alt="" className="winter_four_one_img3" />
                                                  </div>

                                                  <div className="winter_four_one_center">
                                                       <p style={{ color: "#d72e5d", fontSize: '3vw' }}>{item.fromUserName}</p>
                                                       <p style={{ fontSize: '3vw' }}>{item.toUserName}</p>
                                                  </div>
                                                  <div className="winter_four_one_right">
                                                       <p style={{ fontSize: '3.5vw' }}>{item.amount}</p>
                                                       <p style={{ color: '#c1b2b0', fontSize: '3vw' }}>人气值</p>
                                                  </div>
                                             </div>
                                        )
                                   })
                              )}
                         </div>

                         <div className="winter_footer">
                              <img src={winter_menu} alt="" style={{ width: "100%" }} />
                              <div className="winter_footer_one">
                                   <div style={{ width: '70%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                                        <p className="winter_footer_p">{isNvfooter.ranking}</p>
                                        <img src={isNvfooter.userAvatarUrl} alt="" className="winter_footer_img" />
                                        <p className="winter_footer_p">{isNvfooter.nickname}</p>
                                   </div>
                                   <div style={{ width: '30%', textAlign: "right", marginRight: '5%' }}>
                                        <p className="winter_footer_p1">{isNvfooter.diffScore}</p>
                                        <p className="winter_footer_p2">距离上一名</p>
                                   </div>
                              </div>
                         </div>
                    </>
               )}

               {isNan && (
                    <>
                         <div className="winter_three">
                              <p className="winter_two_p">总TOP5奖励</p>
                              <img src={winter_goodbg2} alt="" className="winter_two_bg" style={{ width: "100%" }} />
                              <p className="winter_two_p2">送出任意礼物可获得告白值</p>
                              <p className="winter_two_p3">1钻=100告白值</p>
                              <img src={winter_girlGift2} alt="" className="winter_two_img" />
                              <img src={winter_more2} alt="" className="winter_two_img2" />
                              <p className="winter_two_p4" onClick={moreClick}>查看更多</p>
                         </div>

                         <div className="winter_four" >
                              {isNanList.length === 0 ? (
                                   data.map((item, index) => {
                                        return (
                                             <div className="winter_four_one" key={index}>
                                                  <div className="winter_four_one_left">
                                                       <img src={winter_top1} alt="" className="winter_four_one_img1" />
                                                       <p className="winter_four_one_p1" style={{ marginLeft: '-18%' }}>{item.id}</p>
                                                       <img src={winter_default} alt="" className="winter_four_one_img2" />
                                                  </div>

                                                  <div className="winter_four_one_center">
                                                       <p style={{ fontSize: '3vw' }}>{item.moName}</p>
                                                  </div>
                                                  <div className="winter_four_one_right">
                                                       <p style={{ fontSize: '3.5vw' }}>{item.amount}</p>
                                                       <p style={{ color: '#c1b2b0', fontSize: '3vw' }}>人气值</p>
                                                  </div>

                                             </div>
                                        )
                                   })

                              ) : (
                                   isNanList.map((item, index) => {
                                        return (
                                             <div className="winter_four_one" key={index}>
                                                  <div className="winter_four_one_left">
                                                       <img src={winter_top1} alt="" className="winter_four_one_img1" />
                                                       <p className="winter_four_one_p1">{item.id}</p>
                                                       <img src={item.avatarUrl ? item.avatarUrl : winter_default} alt="" className="winter_four_one_img2" />
                                                  </div>

                                                  <div className="winter_four_one_center" style={{marginLeft:'5   %'}}>
                                                       <p style={{ fontSize: '3vw', }}>{item.nickName}</p>
                                                  </div>
                                                  <div className="winter_four_one_right" style={{ marginLeft: "34%" }}>
                                                       <p style={{ fontSize: '3.5vw' }}>{item.amount}</p>
                                                       <p style={{ color: '#c1b2b0', fontSize: '3vw' }}>告白值</p>
                                                  </div>
                                             </div>
                                        )
                                   })
                              )}

                         </div>

                         <div className="winter_footer">
                              <img src={winter_menu} alt="" style={{ width: "100%" }} />
                              <div className="winter_footer_one">
                                   <div style={{ width: '70%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                                        <p className="winter_footer_p">{isNanfooter.ranking}</p>
                                        <img src={isNanfooter.userAvatarUrl} alt="" className="winter_footer_img" />
                                        <p className="winter_footer_p">{isNanfooter.nickname}</p>
                                   </div>
                                   <div style={{ width: '30%', textAlign: "right", marginRight: '5%' }}>
                                        <p className="winter_footer_p1">{isNanfooter.diffScore}</p>
                                        <p className="winter_footer_p2">距离上一名</p>
                                   </div>
                              </div>
                         </div>
                    </>

               )}

               {isFirstVisit && (
                    <div className="winter_six" >
                         <div className="winter_six_one">
                              <img src={winter_tan} className="winter_six_img1" onClick={bgClick} />

                              <img src={winter_bian} className="winter_six_img2" />
                              <p className="winter_six_p1">座驾飞鱼</p>
                              <p className="winter_six_p2">*有效期1天，请在我的装扮中查看</p>
                              <img src={winter_btn} className="winter_six_img3" onClick={furlClick} />
                         </div>
                    </div>
               )}

               {isMore && (
                    <div className="winter_five" onClick={bgClick}>
                         <div className="winter_five_one" style={{ paddingTop: '7%', paddingBottom: '7%' }}>
                              <img src={winter_title_gb} alt="" className="winter_five_img1" style={{ top: '-6%' }} />
                              <p className="winter_five_one_p1" style={{ top: '-4%', left: '40%' }}>总TOP5奖励</p>

                              <table>
                                   <thead>
                                        <tr>
                                             <th></th>
                                             <th>头饰</th>
                                             <th>气泡</th>
                                             <th>声波</th>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        <tr>
                                             <th>TOP1</th>
                                             <td>15天</td>
                                             <td>15天</td>
                                             <td>15天</td>
                                        </tr>
                                        <tr>
                                             <th>TOP2</th>
                                             <td>10天</td>
                                             <td>10天</td>
                                             <td>10天</td>
                                        </tr>
                                        <tr>
                                             <th>TOP3</th>
                                             <td>7天</td>
                                             <td>7天</td>
                                             <td>7天</td>
                                        </tr>
                                        <tr>
                                             <th>TOP4-5</th>
                                             <td>5天</td>
                                             <td>5天</td>
                                             <td>5天</td>
                                        </tr>
                                   </tbody>
                              </table>
                         </div>
                    </div>
               )}

               {isRule && (
                    <div className="winter_five" onClick={bgClick}>
                         <div className="winter_five_one">
                              <img src={winter_title_gb} alt="" className="winter_five_img1" />
                              <p className="winter_five_one_p1">活动规则</p>


                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: "2%" }}>
                                   <img src={winter_redHeart} alt="" className="winter_five_img2" />
                                   <p className="winter_five_one_p2">活动说明</p>
                              </div>
                              <p className="winter_five_one_p3" style={{ marginTop: '3%' }}>1、告白头条:每小时人气值Top1登顶头条展示1小时</p>
                              <p className="winter_five_one_p3">2、女神榜单根据收到任意礼物获得的人气值进行排名</p>
                              <p className="winter_five_one_p3">3、骑士榜单根据送出任意礼物获得的告白值进行排名</p>
                              <p className="winter_five_one_p3">4、玩家首次进入活动页面可领取一份装扮奖励，可在我的装扮中查看与使用</p>


                              <div style={{ display: 'flex', alignItems: 'center', margin: '3% 0 2% 0' }}>
                                   <img src={winter_redHeart} alt="" className="winter_five_img2" />
                                   <p className="winter_five_one_p2">奖励说明</p>
                              </div>
                              <p className="winter_five_one_p4">女神榜/骑士榜 总榜TOP5奖励</p>
                              <table>
                                   <thead>
                                        <tr>
                                             <th></th>
                                             <th>头饰</th>
                                             <th>气泡</th>
                                             <th>声波</th>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        <tr>
                                             <th>TOP1</th>
                                             <td>15天</td>
                                             <td>15天</td>
                                             <td>15天</td>
                                        </tr>
                                        <tr>
                                             <th>TOP2</th>
                                             <td>10天</td>
                                             <td>10天</td>
                                             <td>10天</td>
                                        </tr>
                                        <tr>
                                             <th>TOP3</th>
                                             <td>7天</td>
                                             <td>7天</td>
                                             <td>7天</td>
                                        </tr>
                                        <tr>
                                             <th>TOP4-5</th>
                                             <td>5天</td>
                                             <td>5天</td>
                                             <td>5天</td>
                                        </tr>
                                   </tbody>
                              </table>

                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: "2%", marginTop: '3%' }}>
                                   <img src={winter_redHeart} alt="" className="winter_five_img2" />
                                   <p className="winter_five_one_p2">特别提示</p>
                              </div>
                              <p className="winter_five_one_p3" style={{ marginTop: '3%' }}>1、奖励于活动结束后3个工作日内发放;</p>
                              <p className="winter_five_one_p3">2、该活动旨在提升直播间娱乐性的互动功能，本互动功能中的虚拟礼物不可反向兑换成现金、游戏豆及其他有价值的财物。您承诺不使用本功能进行任何违反法律法规及本平台规则、规范的活动或行为:</p>
                              <p className="winter_five_one_p3">3、本活动与苹果官方无关</p>
                              <p className="winter_five_one_p3">4、本次活动最终解释权归平台所有</p>

                         </div>
                    </div>
               )}




               {/* <div className="winter_footer">
                    <img src={winter_menu} alt="" style={{ width: "100%" }} />
                    <div className="winter_footer_one">
                         <div style={{ width: '70%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                              <p className="winter_footer_p">未上榜</p>
                              <img src={winter_default} alt="" className="winter_footer_img" />
                              <p className="winter_footer_p">大吉</p>
                         </div>
                         <div style={{ width: '30%', textAlign: "right", marginRight: '5%' }}>
                              <p className="winter_footer_p1">3445</p>
                              <p className="winter_footer_p2">距离上一名</p>
                         </div>
                    </div>
               </div> */}
          </>

     )
}

export default WinterPromise;
