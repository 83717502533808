import React from "react";

function TypesFraud() {
     document.title ='警惕常见诈骗类型'

     document.body.style="'height':'90vh'"

     return (
          <div style={{ width: '100%'}}>
               <div style={{ width: '90%', margin: '5px auto', }}>
                    <h2 style={{ textAlign: 'center', margin: '0 0 10px 0', fontWeight: 500, fontSize: '15px'}}>2024.11.08</h2>
                    <div style={{ lineHeight: '20px', fontSize:'14px'}}>
                         <p>1.请认准本产品官方充值渠道，请勿点击来历不明的连接，不要轻易进行转账操作！</p>
                         <p>2.谨防以刷单为由的转账、付款等情况，平台不会出现刷单业务！</p>
                         <p>3.平台使用过程中不会要求您进行私下转账交易行为，私下转账无法受到官方平台交易保护，平台无法为您做出处理！</p>
                         <p>4.平台下单一切以实际订单进行服务，私下联系交易属违规行为，不受平台保护，财务安全无法保证！</p>
                         <p>5.谨防未成年人被诈骗，不要轻信领取游戏代币等信息！</p>
                    </div>
                    
               </div>
          </div>
     )
}

export default TypesFraud;
