import React, { useState, useEffect } from "react";
import "../css/DiamondRechargeList.css";
import Api from "../utils/Api";

import diamond_bg from "../images/DiamondRechargeList/背景@3x.png";
import zuanImg from "../images/DiamondRecharge/钻石.png";
import wxImgs from "../images/DiamondRechargeList/微信支付@2x.png";
import zfbImgs from "../images/DiamondRechargeList/支付宝@2x.png";
import yxdImgs from "../images/DiamondRechargeList/游戏豆@2x.png";
import xuanImg1 from "../images/DiamondRechargeList/xuanzhong@2x.png";
import xuanImg2 from "../images/DiamondRechargeList/未选中@2x.png";

function DiamondRechargeList() {
    document.title = '钻石充值'

    const [selected, setSelected] = useState("4");
    const [List, setList] = useState([]);
    const [balance, setbalance] = useState(null);//余额
    const [skuListLength, setskuListLength] = useState(null);//显示几种支付方式

    const getListFn = () => Api.liList().then((res) => {
        if (res.data.code === 200) {
            setskuListLength(res.data.data.skuList.length); //显示几种支付方式

            setbalance(res.data.balance)
            const selectedPaymentMethod = selected;
            let selectedSkuList;
            if (selectedPaymentMethod === "4") {
                selectedSkuList = res.data.data.skuList[0].skus;
            } else if (selectedPaymentMethod === "2") {
                selectedSkuList = res.data.data.skuList[1].skus;
            }else{
                selectedSkuList = []
            }
            setList(selectedSkuList);
        }
    });
    useEffect(() => {
        getListFn();
    }, [selected]);

    
    const [selectedDiv, setSelectedDiv] = useState(0);
    const [skuId, setskuId] = useState(3);
    const handleClick = (index, item) => {
        setskuId(item.id)
        setSelectedDiv(index);
    };

    


    const [wxImg, setWxImg] = useState(xuanImg1);
    const [zfbImg, setZfbImg] = useState(xuanImg2);
    const [yxdImg, setYxdImg] = useState(xuanImg2);
    const radioClick = (payment) => {//支付方式
        console.log(payment);

        if (payment === '4') {
            setWxImg(xuanImg1);
            setZfbImg(xuanImg2);
            setYxdImg(xuanImg2);
        } else if (payment === '2') {
            setWxImg(xuanImg2);
            setZfbImg(xuanImg1);
            setYxdImg(xuanImg2);
        } else if (payment == '游戏豆') {
            setWxImg(xuanImg2);
            setZfbImg(xuanImg2);
            setYxdImg(xuanImg1);
        }

        setSelected(payment);
    };

    const [pop, setpop] = useState(false);//弹窗
    const btnClick = () => {
        setpop(true)
    };


    return (
        <>
            <div className="diamond_one">
                <img src={diamond_bg} alt="" className="img1" />

                <div className="diamond_one_a">
                    <p className="p1">钻石:</p>
                    <p className="p2">{ balance == null? 0 : balance }</p>
                    <p className="p3">明细  &gt;</p>
                </div>
            </div>

            <div className="diamond_two">
                <p className="p1">请选择充值金额</p>

                <div className="diamond_two_a">
                    {List.map((item, index) => (
                        <div onClick={() => handleClick(index, item)} key={index} className={selectedDiv === index ? "diamond_two_a_a" : "diamond_two_a_aa"}>
                            <div style={{ width: '100%', margin: '0 0 5% 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={zuanImg} alt="" className="img1" />
                                <p className="p2">{item.amount}</p>
                            </div>
                            <p className="p3">￥{item.price}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="diamond_three">
                <p className="p1">支付方式</p>

                {skuListLength === 1 && (
                    <div className="diamond_three_a" onClick={() => radioClick('4')}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', }}>
                            <img src={wxImgs} alt="" className="img1" />
                            <p className="p2">微信</p>
                        </div>
                        <img src={wxImg} alt="" className="img2" />
                    </div>
                )}
                {skuListLength === 2 && (
                    <>
                        <div className="diamond_three_a" onClick={() => radioClick('4')}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', }}>
                                <img src={wxImgs} alt="" className="img1" />
                                <p className="p2">微信</p>
                            </div>
                            <img src={wxImg} alt="" className="img2" />
                        </div>
                        <div className="diamond_three_a" onClick={() => radioClick('2')}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', }}>
                                <img src={zfbImgs} alt="" className="img1" />
                                <p className="p2">支付宝</p>
                            </div>
                            <img src={zfbImg} alt="" className="img2" />
                        </div>
                    </>
                )}
                {skuListLength === 3 && (
                    <>
                        <div className="diamond_three_a" onClick={() => radioClick('4')}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', }}>
                                <img src={wxImgs} alt="" className="img1" />
                                <p className="p2">微信</p>
                            </div>
                            <img src={wxImg} alt="" className="img2" />
                        </div>
                        <div className="diamond_three_a" onClick={() => radioClick('2')}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', }}>
                                <img src={zfbImgs} alt="" className="img1" />
                                <p className="p2">支付宝</p>
                            </div>
                            <img src={zfbImg} alt="" className="img2" />
                        </div>
                        <div className="diamond_three_a" onClick={() => radioClick('游戏豆')}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', }}>
                                <img src={yxdImgs} alt="" className="img1" />
                                <p className="p2">游戏豆</p>
                            </div>
                            <img src={yxdImg} alt="" className="img2" />
                        </div>
                    </>
                )}
            </div>

            <div className="diamond_four">
                <p>*温馨提示</p>
                <p>1.钻石用于聊天室、消息中购买礼物</p>
                <p>2.充值和活动的钻石不可提现</p>
                <p>3.充值遇到问题，请联系<span style={{ color:'#64b3e5',fontWeight:'bold' }}>我的客服</span></p>
            </div>

            <div className="diamond_five">
                <button onClick={btnClick}>需支付￥18</button>
                {/* 
                    h5=》钻石充值页面{
                        完成了钻石充值页面并动态渲染
                        余额返回null时显示0返回其他数据时正常显示
                        点击时给sku加上紫色边框并拿到当条数据
                        通过切换图片实现单选按钮效果并显示正确的sku数据
                        sku的length为几就显示几种充值方式
                        手写弹窗在点击充值按钮时出现
                    }

                    
                */}
            </div>

            {pop && (
                <>
                    <div className="diamond_six" onClick={() => setpop(false)}>
                        <div className="diamond_six_a">
                            <p className="p1">充值用户</p>
                            <img src={wxImgs} alt="" className="img1" />
                            <p className="p2">ID:345824</p>
                            <p className="p3">系统通知</p>
                            <div style={{ display: "flex", margin: "15px 0px", justifyContent: 'center' }}>
                                <button style={{ width: "100px", height: "40px", color: "#097ce3",fontWeight: 'normal', background: "linear-gradient(to right,#cbe6ff, #f2e2f8)", border: "none", borderRadius: "20px" }}>取消</button>
                                <button style={{ width: "100px", height: "40px", marginLeft: "10px", color: "#FFF", background: "linear-gradient(to right,#0079ff, #f361d9)", border: "none", borderRadius: "20px" }}>
                                    确认
                                </button>
                            </div>
                            <p style={{ color: "#cbcbcb", fontSize: "3vw",textAlign:'center' }}>*请确认充值用户消息，充错ID无法退款</p>
                        </div>
                    </div>
                </>
            )}

        </>
    );
}

export default DiamondRechargeList;
