import React from "react";
import '../../css/magic.css'
import heart_one from "../../images/magic/心动_1@2x.png";
import heart_two from "../../images/magic/心动_2@2x.png";
import heart_three from "../../images/magic/心动_3@2x.png";
import heart_four from "../../images/magic/心动_4@2x.png";
import heart_five from "../../images/magic/心动_5@2x.png";

function HeartbeatMagic() {
     document.title = '心动魔法'

     return (
          <div>
               <div className="heart_one">
                    <img src={heart_one} alt="" style={{ width: "100%"}}/>
               </div>
               <div className="heart_two">
                    <img src="https://res-dev.51dazi.com/test/app-static/lfmOoH3vI4k6-3huy6nXow.png?x-oss-process=image/resize,w_430,m_lfit" alt="" style={{ width: "100%" }} />
               </div>
               <div className="heart_three">
                    <img src={heart_three} alt="" style={{ width: "100%" }} />
               </div>
               <div className="heart_four">
                    <img src={heart_four} alt="" style={{ width: "100%" }} />
               </div>
               <div className="heart_five">
                    <img src={heart_five} alt="" style={{ width: "100%" }} />
               </div>
          </div>
     )
}

export default HeartbeatMagic;
