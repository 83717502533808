import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from "./view/login";//登录

import Myself from "./view/myself";
import DiamondRecharge from "./view/DiamondRecharge"; //钻石充值

import PrivacyAgreement from "./view/agreement/PrivacyAgreement"; //隐私协议
import UserAgreement from "./view/agreement/UserAgreement"; //用户协议
import CaptainsAgreement from "./view/agreement/CaptainsAgreement"; //队长协议
import TypesFraud from "./view/agreement/TypesFraud"; //警惕常见诈骗类型
import NameAuthentication from "./view/agreement/NameAuthentication"; //实名认证服务协议
import CaptainsBehavior from "./view/agreement/CaptainsBehavior"; //队长行为规范及处罚规则
import GreenConvention from "./view/GreenConvention"; //绿色公约

import HeartbeatMagic from "./view/magic/HeartbeatMagic"; //心动魔法
import LovesongMagic from "./view/magic/LovesongMagic"; //恋曲魔法
import HoneyloveMagic from "./view/magic/HoneyloveMagic"; //蜜恋魔法

import WinterPromise from "./view/WinterPromise"; //冬日之约

import QueueCarnival from "./view/QueueCarnival"; //派队狂欢榜

import HappyChristmasParty from "./view/HappyChristmasParty"; //欢乐圣诞趴
import HappyChristmasParty_rulePage from "./view/HappyChristmasParty_rulePage"; //欢乐圣诞趴

import DiamondRechargeList from "./view/DiamondRechargeList"; //钻石充值



function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Login />}></Route>
				
				{/* <Route path="/pay/diamond-page" element={<Myself />}></Route> */}
				<Route path="/pay/diamond-page" element={<DiamondRecharge />}></Route> //钻石充值

				<Route path="/agreement/privacy-agreement" element={<PrivacyAgreement />}></Route> //隐私协议
				<Route path="/agreement/user-agreement" element={<UserAgreement />}></Route> //用户协议
				<Route path="/agreement/captains-agreement" element={<CaptainsAgreement />}></Route> //队长协议
				<Route path="/agreement/types-fraud" element={<TypesFraud />}></Route> //警惕常见诈骗类型
				<Route path="/agreement/name-authentication" element={<NameAuthentication />}></Route> //实名认证服务协议
				<Route path="/agreement/captains-behavior" element={<CaptainsBehavior />}></Route> //队长行为规范及处罚规则
				<Route path="/agreement/green-convention" element={<GreenConvention />}></Route> //绿色公约

				<Route path="/magic/heartbeat-magic" element={<HeartbeatMagic />}></Route> //心动魔法
				<Route path="/magic/lovesong-magic" element={<LovesongMagic />}></Route> //恋曲魔法
				<Route path="/magic/honeylove-magic" element={<HoneyloveMagic />}></Route> //蜜恋魔法

				<Route path="/winter-promise" element={<WinterPromise />}></Route> //冬日之约
				
				<Route path="/queue-carnival" element={<QueueCarnival />}></Route> //派队狂欢榜

				<Route path="/happy-christmas-party" element={<HappyChristmasParty />}></Route> //欢乐圣诞趴
				<Route path="/happy-christmas-party-rulepage" element={<HappyChristmasParty_rulePage />}></Route> //欢乐圣诞趴规则页

				<Route path="/pay/diamond-page-list" element={<DiamondRechargeList />}></Route> //钻石充值

			</Routes>
		</BrowserRouter>
	)
}

export default App;
