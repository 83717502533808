import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../css/myself.css";
import { Input, Toast, Modal, Spin, Avatar } from "@douyinfe/semi-ui";
import Api from "../utils/Api";
import zuanImg from "../images/DiamondRecharge/钻石.png";
import xianImg from "../images/DiamondRecharge/横线@3x.png";
import { WxUtil } from "../utils/WxUtil";

export default function DiamondRecharge() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    document.title = '钻石充值';
    useEffect(() => {
        const inWechat = WxUtil.isInWechat();
        console.log("is in wechat: ", inWechat);
        if (inWechat) {
            // new VConsole();
            if (!fetched) {
                WxUtil.getCode();
            }
        }
        return () => <></>;
    }, []); // 加载vconsole

    useEffect(() => {
        getListFn();
    }, []);

    // 获取特定的查询参数
    const vxCode = searchParams.get("code");
    const fetched = searchParams.get("fetched");

    console.log("Current path: ", window.location.href);
    console.log("Current query params: ", vxCode);

    //单选按钮
    const [selected, setSelected] = useState("4");

    const handleRadioClick = (value) => {
        setSelected(value);
        setpayChanel(value);
        console.log(value);
    };

    const [List, setList] = useState([]);
    const getListFn = () =>
        Api.liList().then((res) => {
            if (res.data.code === 200) {
                const selectedPaymentMethod = selected;
                let selectedSkuList;
                if (selectedPaymentMethod === "4") {
                    selectedSkuList = res.data.data.skuList[0].skus;
                } else if (selectedPaymentMethod === "2") {
                    selectedSkuList = res.data.data.skuList[1].skus;
                }
                setList(selectedSkuList);
            }
        });

    //获取input内容
    const inputChange = (value) => {
        if (/^\d+$/.test(value)) {
            // console.log(value);
            settoUserID(value);
        } else {
            Toast.warning('请输入正确的用户id')
        }
    };

    //input失去焦点
    const inputBlur = (e) => {
        //    console.log(e.target.value);
        //    const value = e.target.value;
        //    Api.getNumber(value).then((res) => {
        //        if (res.data.code == 200) {
        //            console.log(res);
        //        } else {
        //            Toast.error("没有");
        //        }
        //    });
    };

    //div选中与未选
    const [selectedDiv, setSelectedDiv] = useState(0);
    const [skuId, setskuId] = useState(3);

    const handleClick = (index, item) => {
        setskuId(item.id);
        setSelectedDiv(index);
        setamount(item.amount);
    };

    const [payChanel, setpayChanel] = useState(2);
    const [amount, setamount] = useState(1);
    const [toUserID, settoUserID] = useState(null);
    const [mpCode, setmpCode] = useState(null);
    const [visible, setVisible] = useState(false);
    const uidInput = useRef();
    const [spinning, setSpinning] = useState(false); // FIXME: Axois http status非200会因为拦截器导致loading关不掉
    const [user, setUser] = useState({ number: "", nickName: "", avatarUrl: "" });

    const showDialog = () => {
        console.log("Cur val, ", uidInput.current.value);
        if (!uidInput.current.value || uidInput.current.value === "") {
            Toast.warning('请输入用户id')
            return;
        }
        setSpinning(true);
        Api.getNumber(uidInput.current.value).then((res) => {
            setSpinning(false);
            if (res.data.code == 200) {
                console.log(res);
                setUser(res.data.data.user);
                //充值按钮
                setVisible(true);
            } else {
                if (res.data.message) {
                    Toast.warning(res.data.message)
                } else {
                    Toast.warning('获取用户失败，请联系客服')
                }
            }
        });
    };
    const queClick = () => {
        setVisible(false);
        //确认按钮
        if (!vxCode) {
            Toast.warning('无法充值，请联系刷新重试或联系客服')
            return;
        }
        setSpinning(true);

        const data = {
            skuId: skuId,
            payChanel: 4,
            //payChanel: payChanel,
            amount: amount,
            toUserId: toUserID,
            //mpCode: mpCode[1],
            mpCode: vxCode,
        };
        // console.log(data);

        Api.placeOrder(data).then((res) => {
            if (res.data.code === 200) {
                const payParams = res.data.data.payParams;
                const payOrderCodes = res.data.data.payOrderCode;

                window.wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: payParams.appId, // 必填，公众号的唯一标识
                    timestamp: payParams.timestamp, // 必填，生成签名的时间戳
                    nonceStr: payParams.nonceStr, // 必填，生成签名的随机串
                    signature: payParams.signature, // 必填，签名
                    jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
                });

                // 配置成功后的回调
                window.wx.ready(() => {
                    // 在这里可以进行微信支付相关的操作
                    if (typeof window.WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                            document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
                        }
                    } else {
                        onBridgeReady(payParams, payOrderCodes);
                    }
                });

                // 配置失败的回调
                window.wx.error((res) => {
                    console.error("微信配置失败:", res);
                });
                return;
            }
            Toast.warning("下单失败, 请联系客服")
            window.location.href = "/pay/diamond-page";
        });
    };

    function onBridgeReady(payParams, payOrderCodes) {
        setSpinning(false);
        window.WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
                appId: payParams.appId,
                timeStamp: payParams.timeStamp,
                nonceStr: payParams.nonceStr,
                package: payParams.packageVal,
                signType: "RSA",
                paySign: payParams.paySign,
            },
            function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    setSpinning(true);
                    Api.payOrderCode(payOrderCodes).then((res) => {
                        setSpinning(false);
                        if (res.data.code === 200) {
                            if (res.data.data.status === 2) {
                                Toast.warning("充值成功")
                                // setVisible(false);
                            }
                        }
                    });
                }
                window.location.href = "/pay/diamond-page";
            }
        );
    }

    const quClick=()=>{
        setVisible(false);
    }

    return (
        <>
            <Spin size="large" spinning={spinning}>                
                <div className="diamond" style={{marginTop: '10px'}}>
                    <p style={{ color: "#FFF", margin: "15px 0 0 23px", fontSize: "14px" }}>充值ID</p>
                    <Input ref={uidInput} onBlur={inputBlur} placeholder=" 请输入充值的用户ID" borderless={true} onChange={inputChange} 
                    style={{ color: "#FFF", margin: "5px 0 0 10px", fontSize: "20px", caretColor: 'auto' }} size="large"></Input>
                    <img src={xianImg} alt="" style={{ width: "95%", position: "absolute", top: "80px", left: "25px", borderRadius: "5px" }} />
                </div>

                <div className="amount">
                    <h4>请选择充值金额</h4>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around",alignItems:'center' }}>
                        {List.map((item, index) => (
                            <div className={selectedDiv === index ? "amount_other" : "amount_others"} onClick={() => handleClick(index, item)} key={index}>
                                <div className="amount_other_top">
                                    <img src={zuanImg} alt="" style={{ width: "15px", height: "15px", }} />
                                    <p>{item.amount}</p>
                                </div>
                                <p>￥{item.price}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="paymentMethod">
                    <h4>支付方式</h4>

                    <div className="radio" style={{backgroundImage: `url('https://td.cdn-go.cn/enterprise_payment/v0.1.2/logo.svg')`, 
                         backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '20px', width: '150px' , paddingLeft: "105px"}} >
                        <input type="radio" name="paymentMethod" value="4" checked={selected === "4"} onChange={(e) => handleRadioClick(e.target.value)} />
                    </div>
                    <div className="radio">
                        <span>支付宝</span>
                        <input type="radio" name="paymentMethod" value="2" checked={selected === "2"} onChange={(e) => handleRadioClick(e.target.value)} />
                    </div>
                </div>

                <div className="tips">
                    <p>*温馨提示: {mpCode}</p>
                    <p>1.钻石用于聊天室、消息中购买礼物</p>
                    <p>2.充值和活动的钻石不可提现</p>
                    <p>3.充值遇到问题，请联系客服</p>
                </div>
                <div className="btnfooter">
                    <div style={{width:'80%',margin:'0 auto'}}>
                        <button className="btn" onClick={showDialog}>
                            立即充值
                        </button>
                    </div>
                
                    <>
                        <Modal
                            visible={visible}
                            centered
                            style={{ width: "70%" }}
                            footer={false}
                        >
                            <div style={{ width: "100%", textAlign: "center", margin: "0 auto" }}>
                                <h3>充值用户</h3>
                                <Avatar src={user?.avatarUrl} style={{ padding: "15px", width: '60px', height: '60px'    }} />
                                <h4 style={{ fontSize: '17px' }}>ID:<span>{user?.number}</span></h4>

                                <p style={{ fontSize: "16px", margin: "10px 0" }}>{user?.nickName}</p>


                                <div style={{ display: "flex", margin: "15px 0px", justifyContent: 'center' }}>
                                    <button onClick={quClick} style={{ width: "100px", height: "40px", color: "#097ce3",fontWeight: 'normal', background: "linear-gradient(to right,#cbe6ff, #f2e2f8)", border: "none", borderRadius: "20px" }}>取消</button>
                                    <button onClick={queClick} style={{ width: "100px", height: "40px", marginLeft: "10px", color: "#FFF", background: "linear-gradient(to right,#0079ff, #f361d9)", border: "none", borderRadius: "20px" }}>
                                        确认
                                    </button>
                                </div>

                                <p style={{ color: "#cbcbcb", fontSize: "12px" }}>*请确认充值用户消息，充错ID无法退款</p>
                            </div>
                        </Modal>
                    </>
                </div>
            </Spin>
        </>
    );
}



