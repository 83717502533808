import React, { useState } from 'react'
import { Modal, Button } from '@douyinfe/semi-ui';
import "../App.css";
import aaa from '../images/download_brower_route.png'
import bbb from '../assets/bg1.png'
import { WxUtil } from '../utils/WxUtil';

function Login() {
    document.title = '游戏搭子';
    
    const [isActive, setIsActive] = useState(false);
    const [isShow, setIsShow] = useState(false);

    const inWechat = WxUtil.isInWechat();
    const download=(e)=>{

        if (inWechat){
            setIsActive(!isActive);
            setIsShow(!isShow);
        }else{
                e.preventDefault();
                window.location.href = 'https://res-dev.51dazi.com/pkg/daziapp.apk';
        }
    }

    
    return (
        <>
            <div className="background-container">
                <img src={bbb} alt="" className="background-container_img"/>

                <Button className="bottom-button" size="large" type="primary" theme="solid" htmlType="button" onClick={download} style={{ zIndex: '9999' }}> 安卓下载 </Button>

            
                {isActive && 
                <div 
                    style={{ 
                        position: 'absolute', 
                        top: 0, left: 0, right: 0, bottom: 0, 
                        backgroundColor: 'rgb(42 42 42 / 50%)', 
                        zIndex: 10,
                    }
                } />}

                {isShow && <img src={aaa} alt="" className="arrow" />}
            </div>
        </>
    );
}

export default Login;