import React, { useEffect, useState } from "react";
import '../css/HappyChristmasParty.css'

import party_bg17 from "../images/HappyChristmasParty/活动规则@2x.png";
import party_bg18 from "../images/HappyChristmasParty/雪备份 3@2x.png";

function HappyChristmasParty_rulePage() {
	document.title = '欢乐圣诞趴'

	const [rulePage, setRulepage] = useState(true);//中奖啦

	return (
		<>
			{/* 规则页 */}
			{rulePage && (
				<>
					<div className="party_night" >
						<div style={{ display: 'flex', justifyContent: 'center', marginTop: '-3%' }}>
							<img src={party_bg17} alt="" className="img1" />
						</div>
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<img src={party_bg18} alt="" className="img2" style={{ marginRight: '3%' }} />
							<p style={{ color: '#FFF', fontSize: '4vw', letterSpacing: '2px' }}>活动时间</p>
							<img src={party_bg18} alt="" className="img2" style={{ marginLeft: '3%' }} />
						</div>
						<div className="party_night_a" style={{ textAlign: 'center' }}>
							<p style={{ margin: '0', fontSize: '3.8vw' }}>12月20日10:00-12月26日23:59</p>
						</div>

						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<img src={party_bg18} alt="" className="img2" style={{ marginRight: '3%' }} />
							<p style={{ color: '#FFF', fontSize: '4vw', letterSpacing: '2px' }}>装饰圣诞树</p>
							<img src={party_bg18} alt="" className="img2" style={{ marginLeft: '3%' }} />
						</div>
						<div className="party_night_a">
							<p>1.做任务集齐4种圣诞装饰一圣诞袜、祝福铃铛、拐杖糖、快乐饼干，全部集齐后可随机获得1款圣诞礼物，支持多次集齐</p>
							<p>2.已获得的圣诞装饰可转赠好友，活动期间内每人5次机会</p>
							<p>3.每日任务0点刷新，活动结束后无法领取礼物</p>
						</div>

						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<img src={party_bg18} alt="" className="img2" style={{ marginRight: '3%' }} />
							<p style={{ color: '#FFF', fontSize: '4vw', letterSpacing: '2px' }}>每晚8点</p>
							<img src={party_bg18} alt="" className="img2" style={{ marginLeft: '3%' }} />
						</div>
						<div className="party_night_a">
							<p>活动期间内，每晚8点至次日晚8点为1期，每期送出活动礼物满2000钻石，即可参与开奖</p>
							<p>每期抽奖时间为当日20点~24点，抽奖机会当日有效，过期作废</p>
						</div>
						

						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<img src={party_bg18} alt="" className="img2" style={{ marginRight: '3%' }} />
							<p style={{ color: '#FFF', fontSize: '4vw', letterSpacing: '2px' }}>守护榜</p>
							<img src={party_bg18} alt="" className="img2" style={{ marginLeft: '3%' }} />
						</div>
						<div className="party_night_a">
							<p>活动期间收到活动礼物即可增加人气值，进行日榜和总榜排名。日榜前三和总榜前五获得奖励。</p>
							<p style={{ margin:'5% 0' }}>0钻石=1人气值</p>
							<p style={{ marginBottom:'3%' }}>日榜奖励</p>
							<table>
								<thead>
									<tr>
										<th></th>
										<th>头饰</th>
										<th>尾灯</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th>TOP1</th>
										<td>5天</td>
										<td>5天</td>
									</tr>
									<tr>
										<th>TOP2</th>
										<td>3天</td>
										<td>3天</td>
									</tr>
									<tr>
										<th>TOP3</th>
										<td>1天</td>
										<td>1天</td>
									</tr>
								</tbody>
							</table>

							<p style={{ marginBottom: '3%' }}>总榜奖励</p>
							<table>
								<thead>
									<tr>
										<th></th>
										<th>头饰</th>
										<th>气泡</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th>TOP1</th>
										<td>7天</td>
										<td>7天</td>
									</tr>
									<tr>
										<th>TOP2-3</th>
										<td>5天</td>
										<td>5天</td>
									</tr>
									<tr>
										<th>TOP34-5</th>
										<td>3天</td>
										<td>3天</td>
									</tr>
								</tbody>
							</table>
						</div>


						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<img src={party_bg18} alt="" className="img2" style={{ marginRight: '3%' }} />
							<p style={{ color: '#FFF', fontSize: '4vw', letterSpacing: '2px' }}>平台说明</p>
							<img src={party_bg18} alt="" className="img2" style={{ marginLeft: '3%' }} />
						</div>
						<div className="party_night_a">
							<p>1、奖励于活动结束后3个工作日内发放</p>
							<p>2、严禁任何影响妨害游戏搭子健康运营的行为，包括但不限于发布引流广告、展开黄赌毒等违规、违法犯罪活动，严禁任何礼物、游戏豆、魔法帽等虚拟资源的交易行为，严禁诱导未成年人打赏，否则，平台将根据法律法规及平台规则严厉处置，由此造成的损失及责任均由账号持有人承担。</p>
							<p>3、用户在参与活动过程中请遵守平台规则，严禁任何影响平台运营、影响活动公平性及用户体验的行为，否则游戏搭子有权采取措施限制部分或全部账号功能或永久封号;若涉及虚拟物品或虚拟道具的，游戏搭子有权取消发放或追回。</p>
							<p>4、若用户违反法律法规、政策或平台规则的，游戏搭子有权依法向主管机关报告并提交用户注册信息、使用记录及其他信息，并可将其对应的行为和平台处理措施在游戏搭子平台予以公示。同时，游戏搭子有权对以上用户进行黑名单管理，对黑名单用户采取包括但不限于禁止重新注册账号等措施。</p>
						</div>


					</div>
				</>
			)}
		</>
	)
}

export default HappyChristmasParty_rulePage;
