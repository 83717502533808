import React from "react";

function CaptainsBehavior() {

     document.title = '队长行为规范及处罚规则'

     return (
          <div style={{ width: '100%',height:'1300px' }}>
               <div style={{ width: '90%', margin: '5px auto',  }}>
                    <p style={{ lineHeight: '20px',fontSize:'15px' }}>
                         尊敬的各位队长，为建设一个和谐、绿色、健康开黑平台，现发布《队长行为规范及处罚规则》，针对违规情况做出的分级并明晰处罚规则，请熟知并了解贵方应承担的义务和责任。
                    </p>
                    <p style={{ fontWeight: 600,margin:'10px 0' }}>一、严重违规</p>
                    <div style={{ lineHeight: '22px' ,fontSize:'14px'}}>
                         <p>1、传播违法信息行为：涉及色情传播/有色情传播意向、涉政、赌博、诈骗等各项违法行为(举报证据时效性为30日)，情节严重将移交司法部门协助处理。</p>
                         <p>2、传播外平台广告信息行为：在平台或私聊任何地点、任何情况下宣传外平台或广告。</p>
                         <p>3、严重违规一经核实，将对违规账号进行“永久封号、账户余额冻结”处理。</p>
                    </div>
                    
                    <p style={{ fontWeight: 600, margin:'10px 0' }}>二、中度违规</p>
                    <div style={{ lineHeight: '22px', fontSize: '14px' }}>
                         <p>1、买卖流水行为:发生买卖流水行为的，将对违规账号进行冻结处理。年度第一次冻结三天后可扣除1000游戏豆解封;年度第二次冻结七天后可扣除2000游戏豆解封;年度第三次冻结一年，到期解封。</p>
                         <p>2、诱导他人违规行为:主动联系他人、诱导、教唆他人套现或开假车的，将对违规账号进行封停30日处理。季度首次封禁1000游戏豆罚款，立即解封;季度第二次违规罚款2000游戏豆，封停账号时长缩减至24小时;第三次违规不可罚款解封，到期自动解封。</p>
                         <p>3、内部拉人行为:发生内部拉人行为的，对违规账号将对账号进行封停一年处理。季度首次封禁1000游戏豆罚款，封停账号时长缩减至48小时;季度第二次违规罚款2000游戏豆，封停账号时长缩减至72小时;第三次违规不可罚款解封，到期自动解封。</p>
                         <p>4、外部拉人行为:发生外部拉人行为的，对违规账号进行永久封停，并封禁该账号iP地址、mac地址。如厅内管理人员拉人，则同时解除厅内管理权限。</p>
                         <p>5、一人多号入职行为:发生一人多号入职行为的，对违规账号中流水最高的账号进行永久冻结。季度首次罚款2000游戏豆，立即解冻;季度内非首次不可解封。</p>
                         <p>6、外平台任职行为:发生外平台任职行为的，对违规账号进行封停一年处理。季度首次封禁1000游戏豆罚款，封停账号时长缩减至24小时;季度第二次违规罚款2000游戏豆，封停账号时长缩减至48小时;第三次违规不可罚款解封，到期自动解封。</p>
                         <p>7、开假车行为:发生开假车行为的，对违规账号进行封停一年处理。季度首次封禁可缴纳1000游戏豆罚款，立即解封;季度第二次封禁可缴纳2000游戏豆罚款，封停账号时长缩减至24小时;季度第三次封禁不可罚款解封，到期自动解封。</p>
                    </div>

                    <p style={{ fontWeight: 600,margin:'10px 0' }}>三、轻度违规</p>
                    <div style={{ lineHeight: '22px', fontSize: '14px' }}>
                         <p>1、开车期间服务态度恶劣。</p>
                         <p>2、不服从管理，诋毁、侮辱官方考核、管理人员。</p>
                         <p>3、在平台内辱骂，诋毁其他队长或用户。</p>
                         <p>4、轻度违规一经发现核实，根据情节严重程度封停平台账户1-7天。</p>
                         <p>5、发生其他扰乱平台秩序行为的，对账号进行封禁24小时处理，到期自动解封。</p>
                    </div>
                    
                    <p style={{ fontWeight: 600,margin:'10px 0' }}>四、附则</p>
                    <div style={{ lineHeight: '22px', fontSize: '14px' }}>
                         <p>1、本平台只受理证据时效为7日内的举报(除严重违规第1条)，举报证据时效超过7日的平台不予受理。</p>
                         <p>2、本规则内封禁次数均按季度累计，任意违规项封禁都计入季度封禁次数。</p>
                         <p>3、违规行为一经核实，根据上述规则进行处罚。如发生违规后经平台认定情节严重者，平台有权直接进行包括但不限于:永久封停账号、冻结账户余额、移送司法机关等方式进行处理。</p>
                         <p>4、本平台可随时更新本规则，对本规则做出更新的，以平台公示为准。</p>
                         <p style={{ marginBottom: '25px', display: 'inline-block' }}>5、游戏搭子平台对《队长行为规范及处罚规则》中的定义、释义拥有最终解释权。</p>
                    </div>
               </div>
          </div>
     )
}

export default CaptainsBehavior;
