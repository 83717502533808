import React, { useEffect, useState } from "react";
import '../css/HappyChristmasParty.css'
import { useNavigate } from "react-router-dom";


import party_bg1 from "../images/HappyChristmasParty/头图@2x.png";
import party_gift1 from "../images/HappyChristmasParty/活动礼物@2x.png";
import party_btn1 from "../images/HappyChristmasParty/装饰圣诞树_1@2x.png";
import party_btn2 from "../images/HappyChristmasParty/守护榜_2@2x.png";
import party_btn3 from "../images/HappyChristmasParty/每日8点_2@2x.png";
import party_gift2 from "../images/HappyChristmasParty/圣诞礼物@2x.png";
import party_bg2 from "../images/HappyChristmasParty/圣诞树_bg@2x.png";
import party_gift4 from "../images/HappyChristmasParty/铃铛@2x.png";
import party_gift5 from "../images/HappyChristmasParty/拐杖糖@2x.png";
import party_gift6 from "../images/HappyChristmasParty/编组 29备份@2x.png";
import party_gift7 from "../images/HappyChristmasParty/圣诞袜子@2x.png";
import party_btn4 from "../images/HappyChristmasParty//红色按钮_背景@2x.png";
import party_btn5 from "../images/HappyChristmasParty/黄色按钮背景@2x.png";
import party_btn6 from "../images/HappyChristmasParty/领取礼物_按钮@2x.png";
import party_btn7 from "../images/HappyChristmasParty/送给好友_2@2x(1).png";
import party_bg3 from "../images/HappyChristmasParty/任务_bg@2x.png";
import party_bg4 from "../images/HappyChristmasParty/背景@2x.png";
import party_btn8 from "../images/HappyChristmasParty/签到@2x.png";
import party_btn9 from "../images/HappyChristmasParty/按钮1@2x.png";
import party_btn10 from "../images/HappyChristmasParty/装饰圣诞树_2@2x.png";
import party_btn11 from "../images/HappyChristmasParty/守护榜_1@2x.png";
import party_btn12 from "../images/HappyChristmasParty/每日8点_1@2x.png";
import party_gift8 from "../images/HappyChristmasParty/总榜奖励@2x.png";
import party_bg5 from "../images/HappyChristmasParty/排行榜_bg@2x.png";
import party_touxiang from "../images/HappyChristmasParty/1.png";
import party_bg6 from "../images/HappyChristmasParty/鹿角_标题@2x.png";
import party_gift9 from "../images/HappyChristmasParty/日榜前三榜奖励@2x.png";
import party_gift10 from "../images/HappyChristmasParty/星形@2x.png";
import party_gift11 from "../images/HappyChristmasParty/最佳助力@2x(1).png";
import party_bg7 from "../images/HappyChristmasParty/底部_背景.png";
import party_bg8 from "../images/HappyChristmasParty/每日8点_bg@2x.png";
import party_btn13 from "../images/HappyChristmasParty/按钮@2x.png";
import party_bg9 from "../images/HappyChristmasParty/弹窗_bg@2x.png";
import party_btn14 from "../images/HappyChristmasParty/收下按钮@2x.png";
import party_bg10 from "../images/HappyChristmasParty/好友列表_bg@2x.png";
import party_btn15 from "../images/HappyChristmasParty/取消_按钮@2x.png";
import party_btn16 from "../images/HappyChristmasParty/发送按钮@2x.png";
import party_bg11 from "../images/HappyChristmasParty/我的抽奖记录_bg@2x.png";
import party_bg12 from "../images/HappyChristmasParty/全部抽奖记录@2x.png";
import party_bg13 from "../images/HappyChristmasParty/我的记录_bg@2x.png";
import party_bg14 from "../images/HappyChristmasParty/弹窗背景.webp";
import party_bg15 from "../images/HappyChristmasParty/恭喜获得@2x.png";
import party_btn17 from "../images/HappyChristmasParty/知道了@2x.png";
import party_bg16 from "../images/HappyChristmasParty/中奖啦_标题@2x.png";
import party_btn18 from "../images/HappyChristmasParty/抽奖@2x.png";
import party_btn19 from "../images/HappyChristmasParty/按钮2@2x.png";
import party_btn20 from "../images/HappyChristmasParty/按钮3@2x.png";
import party_btn21 from "../images/HappyChristmasParty/开车@2x.png";
import party_btn22 from "../images/HappyChristmasParty/信息@2x.png";
import party_btn23 from "../images/HappyChristmasParty/充值@2x.png";
import party_btn24 from "../images/HappyChristmasParty/聊天室@2x.png";




function HappyChristmasParty() {
    document.title = '欢乐圣诞趴'

    const navigate = useNavigate();

    const data = [
        {
            name: '呃呃呃',
            id: "1"
        },
        {
            name: '呃呃呃',
            id: "1"
        },
    ]
    const data1 = [
        {
            date: '12月24日',
        },
        {
            date: '12月25日',
        },
        {
            date: '12月26日',
        },
        {
            date: '12月27日',
        },
        {
            date: '12月28日',
        },
        {
            date: '12月29日',
        }
    ]

    const [isDecoration, setDecoration] = useState(true);//装饰圣诞树
    const [isGuardian, setGuardian] = useState(false);//守护榜
    const [isEight, setEight] = useState(false);//每晚8点

    const [isriColor, setriColor] = useState(false);//守护日榜变色
    const [iszongColor, setzongColor] = useState(true);//守护总榜变色
    const [dateIndex, setdateIndex] = useState(0);//日榜:日期按钮


    const [acceptGifts, setAcceptGifts] = useState(false);//收礼弹窗
    const [friendList, setFriendList] = useState(false);//好友列表弹窗
    const [popBackup, setPopBackup] = useState(false);//弹窗备份
    const [myLottery, setMyLottery] = useState(false);//我的抽奖记录
    const [allWinning, setAllWinning] = useState(false);//全部中奖记录
    const [myRecords, setMyRecords] = useState(false);//我的记录
    const [obtain, setTobtain] = useState(false);//获得
    const [winning, setWinning] = useState(false);//中奖啦


    const decorationClick = () => {//装饰圣诞树
        setDecoration(true);
        setGuardian(false);
        setEight(false);
    };

    const guardianClick = () => {//守护榜
        setDecoration(false);
        setGuardian(true);
        setEight(false);
    };

    const eightClick = () => {//每晚8点
        setDecoration(false);
        setGuardian(false);
        setEight(true);
    };

    const riClick = () => {//守护日榜变色
        setriColor(true);
        setzongColor(false);
    };

    const zongClick = () => {//守护总榜变色
        setriColor(false);
        setzongColor(true);
    };

    const dateClick = (index) => {//日榜:日期按钮
        setdateIndex(index);
    };

    const ruleClick = () => {//活动规则按钮
        navigate("/happy-christmas-party-rulepage");
    };

    const recordClick = () => {//我的记录按钮
        // console.log('111');
        setMyRecords(true)
    };

    const guanClick = () => {//关闭弹窗
        setAcceptGifts(false)//收礼弹窗
        setFriendList(false)//好友列表弹窗
        setPopBackup(false)//弹窗备份
        setMyLottery(false)//我的抽奖记录
        setAllWinning(false)//全部中奖记录弹窗
        setMyRecords(false)//我的记录弹窗
        setTobtain(false)//获得弹窗
        setWinning(false)//中奖啦弹窗
    }

    const receiveClick = () => {//领取礼物按钮
        setTobtain(true)
    };

    const SendClick = () => {//送给好友按钮
        setFriendList(true)
    };

    const giftCardClick = () => {//赠送卡片按钮
        setFriendList(false)
        setPopBackup(true)
    };

    const allRecordsClick = () => {//全部中奖记录按钮
        setAllWinning(true)
    };

    const lotteryClick = () => {//待解锁抽奖按钮
        setWinning(true)
    };

    const mylotteryClick = () => {//我的抽奖记录
        setMyLottery(true)
    };

    
    


    return (
        <>
            <div className="party_bg">
                <img src={party_bg1} alt="" className="img1" />
                <img src={party_gift1} alt="" className="img2" />

                <p className="party_rule" onClick={ruleClick}>活动规则</p>
            </div>


            <div className="party_bg_one">
                <img src={isDecoration ? party_btn1 : party_btn10} alt="" className="img3" onClick={decorationClick} />
                <img src={isGuardian ? party_btn11 : party_btn2} alt="" className="img3" onClick={guardianClick} />
                <img src={isEight ? party_btn12 : party_btn3} alt="" className="img4" onClick={eightClick} />
            </div>


            {/* 装饰圣诞树 */}
            {isDecoration && (
                <>
                    <div className="party_one">
                        <img src={party_gift2} alt="" className="img1" />
                    </div>

                    <div className="party_two">
                        <img src={party_bg2} alt="" className="img1" />

                        <p className="party_two_rule" onClick={recordClick}>我的记录</p>

                        <div className="float1">
                            <img src={party_gift4} alt="" className="img2" />
                            <img src={party_btn4} alt="" className="img6" />
                            <p className="p1">祝福铃铛</p>
                        </div>

                        <div className="float2">
                            <img src={party_gift5} alt="" className="img3" />
                            <img src={party_btn5} alt="" className="img7" />
                            <p className="p2">拐杖糖</p>
                            <div className="x3_icon">x3</div>
                        </div>

                        <div className="float3">
                            <img src={party_gift6} alt="" className="img4" />
                            <img src={party_btn4} alt="" className="img6" />
                            <p className="p1">祝福铃铛</p>
                        </div>

                        <div className="float4">
                            <img src={party_gift7} alt="" className="img5" />
                            <img src={party_btn5} alt="" className="img7" />
                            <p className="p1" style={{ marginLeft: '26%' }}>圣诞袜</p>
                            <div className="x3_icon">x1</div>
                        </div>

                        <div className="party_two_bottom">
                            <img src={party_btn6} alt="" className="img8" onClick={receiveClick}/>
                            <img src={party_btn7} alt="" className="img9" onClick={SendClick} />
                            <p className="p3">(0/5)</p>
                        </div>
                    </div>

                    <div className="party_three">
                        <div className="party_three_a" style={{ marginTop: '2%' }}>
                            <div style={{ width: '19%', }}>
                                <img src={party_btn8} alt="" className="img3" />
                            </div>
                            <div style={{ width: '50%', marginLeft: '-1%' }}>
                                <p className="p1">活动页面签到一次</p>
                            </div>
                            <div style={{ width: '20%', }}>
                                <img src={party_btn9} alt="" className="img4" />
                            </div>
                        </div>
                        <div className="party_three_a" style={{ marginTop: '2%' }}>
                            <div style={{ width: '19%', }}>
                                <img src={party_btn22} alt="" className="img3" />
                            </div>
                            <div style={{ width: '50%', marginLeft: '-1%' }}>
                                <p className="p1" style={{ width:'95%' }}>房间公屏发送5条消息(0/5)</p>
                            </div>
                            <div style={{ width: '20%', }}>
                                <img src={party_btn19} alt="" className="img4" />
                            </div>
                        </div>
                        <div className="party_three_a" style={{ marginTop: '2%' }}>
                            <div style={{ width: '19%', }}>
                                <img src={party_btn23} alt="" className="img3" />
                            </div>
                            <div style={{ width: '50%', marginLeft: '-1%' }}>
                                <p className="p1">任意充值1次(0/5)</p>
                            </div>
                            <div style={{ width: '20%', }}>
                                <img src={party_btn20} alt="" className="img4" />
                            </div>
                        </div>
                        <div className="party_three_a" style={{ marginTop: '2%' }}>
                            <div style={{ width: '19%', }}>
                                <img src={party_btn24} alt="" className="img3" />
                            </div>
                            <div style={{ width: '50%', marginLeft: '-1%' }}>
                                <p className="p1">任意聊天室累计停留15分钟(0/15)</p>
                            </div>
                            <div style={{ width: '20%', }}>
                                <img src={party_btn9} alt="" className="img4" />
                            </div>
                        </div>
                        <div className="party_three_a" style={{ marginTop: '2%' }}>
                            <div style={{ width: '19%', }}>
                                <img src={party_btn21} alt="" className="img3" />
                            </div>
                            <div style={{ width: '50%', marginLeft: '-1%' }}>
                                <p className="p1">开有效车3次(0/5)</p>
                            </div>
                            <div style={{ width: '20%', }}>
                                <img src={party_btn9} alt="" className="img4" />
                            </div>
                        </div>
                    </div>
                </>
            )}


            {/* 守护榜 */}
            {isGuardian && (
                <>
                    <div className="party_four">
                        <button className={isriColor ? 'btn11' : 'btn1'} onClick={riClick}>守护日榜</button>
                        <button className={iszongColor ? 'btn2' : 'btn22'} onClick={zongClick}>守护总榜</button>
                    </div>

                    {/* 守护日榜 */}
                    {isriColor && (
                        <>
                            <div className="party_six">
                                <img src={party_gift9} alt="" className="img1" />

                                <div className="party_six_a">
                                    {
                                        data1.map((item, index) => (
                                            <button
                                                key={index}
                                                className={index === dateIndex ? 'btn3' : 'btn4'}
                                                onClick={() => dateClick(index)}
                                            >
                                                {item.date}
                                            </button>
                                        ))
                                    }
                                </div>

                                {dateIndex == 0 && (
                                    <div className="party_five">
                                        <img src={party_bg6} alt="" className="img1" />

                                        <div style={{ width: '92%', margin: '0 auto' }}>
                                            <div className="party_five_a">
                                                {
                                                    data.map((item, index) => {
                                                        return (
                                                            <div key={index} className={index === 0 ? "party_five_bbb" : "party_five_bbbb"}>
                                                                <div className="party_five_b_a" >
                                                                    <div style={{ width: '50%', display: 'flex', alignItems: 'center', position: 'relative' }}>
                                                                        <p style={{ marginRight: '7%', fontSize: '4vw', fontWeight: 'bold' }}>4</p>
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <img src={party_touxiang} alt="" className="img6" />
                                                                            <p style={{ fontSize: '2.2vw', fontWeight: 'bold' }}>迷你卡</p>
                                                                        </div>
                                                                        <img src={party_gift10} alt="" className="img7" />
                                                                        <img src={party_gift11} alt="" className="img8" />

                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <img src={party_touxiang} alt="" className="img6" />
                                                                            <p style={{ fontSize: '2.2vw', fontWeight: 'bold' }}>迷你卡</p>
                                                                        </div>

                                                                    </div>
                                                                    <div style={{ width: '50%', textAlign: 'right' }} >
                                                                        <p style={{ fontSize: '3vw' }}>1000000</p>
                                                                        <p style={{ fontSize: '3vw' }}>人气值</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>

                            <div className="party_footer">
                                <img src={party_bg7} alt="" className="img1" />

                                <div className="party_footer_one">
                                    <div style={{ width: '70%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                                        <p className="party_footer_p">未上榜</p>
                                        <img src={party_touxiang} alt="" className="party_footer_img" />
                                        <p className="party_footer_p">暗黑</p>
                                    </div>

                                    <div style={{ width: '30%', textAlign: "center", marginRight: '5%' }}>
                                        <p className="party_footer_p1">1223</p>
                                        <p className="party_footer_p2">距离上一名</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {/* 守护总榜 */}
                    {iszongColor && (
                        <>
                            <img src={party_gift8} alt="" style={{ width: '100%', background: '#651809', paddingBottom: '1%' }} />

                            <div className="party_five">
                                <img src={party_bg6} alt="" className="img1" />

                                <div style={{ width: '92%', margin: '0 auto' }}>
                                    <div className="party_five_a">
                                        <div style={{ width: '100%', position: 'relative', zIndex: '1' }}>
                                            <img src={party_bg5} alt="" className="img2" />
                                            <img src={party_touxiang} alt="" className="img3" />
                                            <img src={party_touxiang} alt="" className="img4" />
                                            <img src={party_touxiang} alt="" className="img5" />

                                            <div className="party_five_a_a">
                                                <p>ROSE鹏鹏饭</p>
                                                <p style={{ margin: '5% 0' }}>ID:12345</p>
                                                <p>人气值100000万</p>
                                            </div>
                                            <div className="party_five_a_b">
                                                <p>ROSE鹏鹏饭</p>
                                                <p style={{ margin: '5% 0' }}>ID:12345</p>
                                                <p>人气值100000万</p>
                                            </div>
                                            <div className="party_five_a_c">
                                                <p>ROSE鹏鹏饭</p>
                                                <p style={{ margin: '5% 0' }}>ID:12345</p>
                                                <p>人气值100000万</p>
                                            </div>
                                        </div>

                                        {
                                            data.map((item, index) => {
                                                return (
                                                    <div key={index} className={index === 0 ? "party_five_bb" : "party_five_b"}>
                                                        <div className="party_five_b_a">
                                                            <div style={{ width: '19%', display: 'flex', alignItems: 'center' }}>
                                                                <p style={{ marginRight: '7%', fontSize: '4vw', fontWeight: 'bold' }}>4</p>
                                                                <img src={party_touxiang} alt="" className="img6" />
                                                            </div>
                                                            <div style={{ width: '50%', marginLeft: "-4%" }}>
                                                                <p style={{ fontSize: '3vw', fontWeight: 'bold' }}>{item.name}</p>
                                                                <p style={{ fontSize: '3vw' }}>ID:12345</p>
                                                            </div>
                                                            <div style={{ width: '20%', textAlign: 'right' }} >
                                                                <p style={{ fontSize: '3vw' }}>1000000</p>
                                                                <p style={{ fontSize: '3vw' }}>人气值</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="party_footer" style={{ bottom: '0%', }}>
                                {/* <img src={party_bg7} alt="" className="img1" /> */}

                                <div className="party_footer_one" style={{ padding: '4% 0%', background: '#651809' }}>
                                    <div style={{ width: '70%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                                        <p className="party_footer_p">未上榜</p>
                                        <img src={party_touxiang} alt="" className="party_footer_img" />
                                        <p className="party_footer_p">暗黑</p>
                                    </div>

                                    <div style={{ width: '30%', textAlign: "center", marginRight: '5%' }}>
                                        <p className="party_footer_p1">3445</p>
                                        <p className="party_footer_p2">距离上一名</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}


            {/* 每晚8点 */}
            {isEight && (
                <>
                    <div className="party_seven">
                        <img src={party_bg8} alt="" className="img1" />

                        <div className="party_seven_a">
                            <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                                <img src={party_touxiang} alt="" className="img2" />
                                <p style={{ marginLeft: "3%" }}>恭喜XXX 获得XXX</p>
                            </div>
                            <button onClick={allRecordsClick}>全部中奖记录</button>
                        </div>

                        <p className="p1">每晚8点至次日晚8点为1期，每期送出活动礼物满2000钻石，即可参与开奖，抽奖时间截止当日24点</p>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {/* <img src={party_btn13} alt="" className="img3" onClick={lotteryClick} /> */}
                            <img src={party_btn18} alt="" className="img3" onClick={lotteryClick} />
                            
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button className="btn5" onClick={mylotteryClick}>我的抽奖记录</button>
                        </div>
                    </div>
                </>
            )}


            {/* 收礼弹窗 */}
            {acceptGifts && (
                <>
                    <div className="party_eight" onClick={guanClick}>
                        <div className="party_eight_a">
                            <img src={party_bg9} className="img1" />

                            <div className="party_eight_a_a">
                                <img src={party_touxiang} alt="" className="img2" />
                                <div style={{ marginLeft: '2%' }}>
                                    <p>莫妮卡</p>
                                    <p style={{ marginTop: '2%' }}>赠送了你一个快乐饼干</p>
                                </div>
                            </div>
                            <div className="party_eight_a_b">
                                <img src={party_gift6} alt="" className="img3" />
                                <p>快乐饼干</p>
                            </div>
                            <img src={party_btn14} alt="" className="img4" />

                        </div>
                    </div>
                </>
            )}


            {/* 好友列表弹窗 */}
            {friendList && (
                <>
                    <div className="party_eight" >
                        <div className="party_eight_a">
                            <img src={party_bg10} className="img1" />

                            <div className="party_eight_a_c">
                                <input type="text" placeholder="请输入用户ID" />
                                <button>搜索</button>
                            </div>
                            <p className="p1">剩余赠送礼物4次</p>
                            <div className="party_eight_a_d">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '2%' }}>
                                    <div style={{ width: '55%', display: 'flex', alignItems: 'center' }}>
                                        <img src={party_touxiang} className="img5" />
                                        <p style={{ marginLeft: '3%', fontSize: '4.5vw', color: '#FFD9A3' }}>莫妮卡</p>
                                    </div>
                                    <button onClick={ giftCardClick }>赠送卡片</button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '2%' }}>
                                    <div style={{ width: '55%', display: 'flex', alignItems: 'center' }}>
                                        <img src={party_touxiang} className="img5" />
                                        <p style={{ marginLeft: '3%', fontSize: '4.5vw', color: '#FFD9A3' }}>莫妮卡</p>
                                    </div>
                                    <button>赠送卡片</button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '2%' }}>
                                    <div style={{ width: '55%', display: 'flex', alignItems: 'center' }}>
                                        <img src={party_touxiang} className="img5" />
                                        <p style={{ marginLeft: '3%', fontSize: '4.5vw', color: '#FFD9A3' }}>莫妮卡</p>
                                    </div>
                                    <button>赠送卡片</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            )}


            {/* 弹窗备份 */}
            {popBackup && (
                <>
                    <div className="party_eight">
                        <div className="party_eight_a">
                            <img src={party_bg9} className="img1"/>

                            <div className="party_eight_a_a">
                                <p>发送给</p>
                                <p style={{ marginLeft: '2%' }}>莫妮卡</p>
                            </div>
                            <div className="party_eight_a_b" style={{ top: "44%" }}>
                                <img src={party_gift6} alt="" className="img3" />
                                <p>快乐饼干</p>
                            </div>
                            <div className="party_eight_a_e">
                                <img src={party_btn15} alt="" className="img5" onClick={guanClick} />
                                <img src={party_btn16} alt="" className="img5" onClick={guanClick} />
                            </div>
                        </div>
                    </div>
                </>
            )}


            {/* 我的抽奖记录 */}
            {myLottery && (
                <>
                    <div className="party_eight" onClick={guanClick}>
                        <div className="party_eight_a">
                            <img src={party_bg10} className="img1" />
                            <img src={party_bg11} className="img11" />

                            <div className="party_eight_a_d" style={{ top: '32%' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5%' }}>
                                    <img src={party_touxiang} className="img5" style={{ width: '15%' }} />
                                    <div style={{ marginLeft: '5%' }}>
                                        <p style={{ fontSize: '4vw', color: '#FFD9A3' }}>头饰1天</p>
                                        <p style={{ fontSize: '3.7vw', color: '#FF9908', marginTop: '5%' }}>12-24 23:59:02</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5%' }}>
                                    <img src={party_touxiang} className="img5" style={{ width: '15%' }} />
                                    <div style={{ marginLeft: '5%' }}>
                                        <p style={{ fontSize: '4vw', color: '#FFD9A3' }}>头饰1天</p>
                                        <p style={{ fontSize: '3.7vw', color: '#FF9908', marginTop: '5%' }}>12-24 23:59:02</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5%' }}>
                                    <img src={party_touxiang} className="img5" style={{ width: '15%' }} />
                                    <div style={{ marginLeft: '5%' }}>
                                        <p style={{ fontSize: '4vw', color: '#FFD9A3' }}>头饰1天</p>
                                        <p style={{ fontSize: '3.7vw', color: '#FF9908', marginTop: '5%' }}>12-24 23:59:02</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            )}


            {/* 全部中奖记录 */}
            {allWinning && (
                <>
                    <div className="party_eight" onClick={guanClick} >
                        <div className="party_eight_a">
                            <img src={party_bg10} className="img1" />
                            <img src={party_bg12} className="img11" />

                            <div className="party_eight_a_d" style={{ top: '30%' }}>
                                <div style={{ width: '70%', margin: '0 auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: ' flex-start', marginBottom: '5%' }}>
                                        <img src={party_touxiang} className="img5" style={{ width: '17%' }} />
                                        <div style={{ marginLeft: '5%' }}>
                                            <p style={{ fontSize: '4vw', color: '#FFD9A3' }}>恭喜莫妮卡</p>
                                            <p style={{ fontSize: '3.3vw', color: '#FF9908', marginTop: '3%' }}>获得奖励 xxx*2天/1个</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: ' flex-start', marginBottom: '5%' }}>
                                        <img src={party_touxiang} className="img5" style={{ width: '17%' }} />
                                        <div style={{ marginLeft: '5%' }}>
                                            <p style={{ fontSize: '4vw', color: '#FFD9A3' }}>恭喜莫妮卡</p>
                                            <p style={{ fontSize: '3.3vw', color: '#FF9908', marginTop: '3%' }}>获得奖励 xxx*2天/1个</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: ' flex-start', marginBottom: '5%' }}>
                                        <img src={party_touxiang} className="img5" style={{ width: '17%' }} />
                                        <div style={{ marginLeft: '5%' }}>
                                            <p style={{ fontSize: '4vw', color: '#FFD9A3' }}>恭喜莫妮卡</p>
                                            <p style={{ fontSize: '3.3vw', color: '#FF9908', marginTop: '3%' }}>获得奖励 xxx*2天/1个</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: ' flex-start', marginBottom: '5%' }}>
                                        <img src={party_touxiang} className="img5" style={{ width: '17%' }} />
                                        <div style={{ marginLeft: '5%' }}>
                                            <p style={{ fontSize: '4vw', color: '#FFD9A3' }}>恭喜莫妮卡</p>
                                            <p style={{ fontSize: '3.3vw', color: '#FF9908', marginTop: '3%' }}>获得奖励 xxx*2天/1个</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}


            {/* 我的记录 */}
            {myRecords && (
                <>
                    <div className="party_eight" onClick={guanClick}>
                        <div className="party_eight_a">
                            <img src={party_bg10} className="img1" />
                            <img src={party_bg13} className="img11" />

                            <p className="p1" style={{ top: '30%', fontSize: '4vw' }}>剩余赠送礼物4次</p>

                            <div className="party_eight_a_d" style={{ top: '40%' }}>
                                <div style={{ width: '70%', margin: '0 auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: ' flex-start', marginBottom: '5%' }}>
                                        <img src={party_touxiang} className="img5" style={{ width: '17%' }} />
                                        <div style={{ marginLeft: '3%' }}>
                                            <p style={{ fontSize: '3.7vw', color: '#FFD9A3' }}>莫妮卡</p>
                                            <p style={{ fontSize: '3vw', color: '#FFD9A3', marginTop: '3%' }}>送给你一个"快乐饼干"</p>
                                        </div>
                                        <p className="p2">12-24 23:59:02</p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: ' flex-start', marginBottom: '5%' }}>
                                        <img src={party_touxiang} className="img5" style={{ width: '17%' }} />
                                        <div style={{ marginLeft: '3%' }}>
                                            <p style={{ fontSize: '3.7vw', color: '#FFD9A3' }}>莫妮卡</p>
                                            <p style={{ fontSize: '3vw', color: '#FFD9A3', marginTop: '3%' }}>送给你一个"快乐饼干"</p>
                                        </div>
                                        <p className="p2">12-24 23:59:02</p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: ' flex-start', marginBottom: '5%' }}>
                                        <img src={party_touxiang} className="img5" style={{ width: '17%' }} />
                                        <div style={{ marginLeft: '3%' }}>
                                            <p style={{ fontSize: '3.7vw', color: '#FFD9A3' }}>莫妮卡</p>
                                            <p style={{ fontSize: '3vw', color: '#FFD9A3', marginTop: '3%' }}>送给你一个"快乐饼干"</p>
                                        </div>
                                        <p className="p2">12-24 23:59:02</p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}


            {/* 获得 */}
            {obtain && (
                <>
                    <div className="party_eight">
                        <div className="party_eight_a">
                            <img src={party_bg14} className="img1" />
                            <img src={party_bg15} className="img111" />

                            <div className="party_eight_a_b">
                                <img src={party_gift7} alt="" className="img3" />
                                <p>圣诞袜x1</p>
                            </div>
                            <img src={party_btn17} alt="" className="img4" onClick={guanClick} />

                        </div>
                    </div>
                </>
            )}

            {/* 中奖啦 */}
            {winning && (
                <>
                    <div className="party_eight" onClick={guanClick}>
                        <div className="party_eight_a">
                            <img src={party_bg14} className="img1" />
                            <img src={party_bg16} className="img111" />

                            <div className="party_eight_a_b">
                                <img src={party_gift7} alt="" className="img3" style={{ marginBottom: '7%' }} />
                                <p>xxx礼物</p>
                            </div>
                            <button className="btn3">有效期3天，请在背包内查看</button>
                        </div>
                    </div>
                </>
            )}


            
        </> 
    )
}

export default HappyChristmasParty;
